<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">报警名称</span>
          </span>
          <el-input v-model="formInline.sosName" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getTable">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="total!=0">
      <el-table :data="tableData" v-loading="loading" style="width: 100%;margin-bottom: 20px;" :height="$store.state.tabHeight">
        <el-table-column type="index" label="序号" width="50px" />
        <el-table-column align='center' prop="sosType" label="报警名称">
          <template slot-scope="scope">
            {{getStatusText(scope.row.sosType)}}
          </template>
        </el-table-column>
        <el-table-column align='center' label="是否推送">
          <template slot-scope="scope">
            <el-switch :disabled="pushId==scope.row.id" :active-value="1" :inactive-value="0"
              @change="pushChange(scope.row)" v-model="scope.row.push"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align='center' label="弹窗">
          <template slot-scope="scope">
            <el-switch disabled :active-value="1" :inactive-value="0" :value="1"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align='center' label="声音">
          <template slot-scope="scope">
            <el-switch :disabled="voiceId==scope.row.id" :active-value="1" :inactive-value="0"
              @change="voiceChange(scope.row)" v-model="scope.row.voice"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align='center' label="报警等级" width="500px">
          <template slot="header" slot-scope="scope">
            <div style="display:flex;justify-content: center;">
              报警等级
              <el-image class="tipsTableTitle" :src="require(`@/assets/tips.png`)" fit="cover"
                style="width:20px; height:20px" @click="openTip" />
            </div>
          </template>
          <template slot-scope="scope">
            <el-radio-group v-model="scope.row.sosLevel" class="radioGroup" @input="levelChange(scope.row)">
              <template v-for="(item, index) in sosLevelArr">
                <el-radio :label="item.value" :key="index">{{item.label}}</el-radio>
              </template>
            </el-radio-group>
          </template>
        </el-table-column>
      </el-table>
      <!-- <Pagination :total="total" :page.sync="formInline.current" :limit.sync="formInline.size"
				@pagination="dictionarPage" /> -->
    </div>
    <div class="nullDate" v-else v-loading="loading">
      <img v-if="!loading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>

    <el-dialog v-if='tipBoxVisibile' :close-on-click-modal='false' :visible.sync="tipBoxVisibile" title="报警等级说明"
      width="1200px" top='15vh' @close="tipBoxVisibile = false">
      <div class="scrollElement tipsBody">
        <div class="tipsBox">
          <div class="tipsHead">
            <div class="tipsTitle fontBlue">
              严重乘梯安全
            </div>
            <div class="tipsTxt"> <span
                class="fontBlue">严重乘梯安全指电梯在运行过程中可能出现的紧急情况(如困人、开门运行、溜梯、冲顶、夹人和伤人等)，容易造成乘客因恐慌、非理性操作而造成乘客的人身安全以及设备财产损失.</span><br /><span
                class="fontBlue">常见报警类型</span>为：困人、冲顶、蹲底、开门运行、安全回路报警、主动报警、语音报警、电动车报警等。<br /><span
                class="fontBlue">报警提醒：</span>平台将进行弹窗提醒。<br />样式如下：</div>
          </div>
          <div>
            <el-image style="width: 100%; " :src="require('@/assets/sosLevel1.png')" fit="cover" />
          </div>
        </div>
        <div class="tipsBox">
          <div class="tipsHead">
            <div class="tipsTitle fontBlue">
              安全预警
            </div>
            <div class="tipsTxt"><span class="fontBlue">安全预警指影响电梯的正常运行和使用，但对乘客人身安全及财产损失风险较小的事件情况。</span><br /><span
                class="fontBlue">常见报警类型</span>：关门异常、开门异常、楼层丢失、超速报警等。<br /><span
                class="fontBlue">报警提醒：</span>平台将进行弹窗提醒。<br />样式如下：</div>
          </div>
          <div>
            <el-image style="width: 100%; " :src="require('@/assets/sosLevel2.png')" fit="cover" />
          </div>
        </div>
        <div class="tipsBox">
          <div class="tipsHead">
            <div class="tipsTitle fontBlue">
              报警提示
            </div>
            <div class="tipsTxt"><span
                class="fontBlue">报警提示指乘客不文明乘梯行为如长时间阻梯造成的，经纠正后可正常乘梯，无使用风险的事件。或因监控设备自身故障如断电造成的事件，不影响乘客正常乘梯的事件。</span><br /><span
                class="fontBlue">常见报警类型：低电量、运行异常等。</span><br /><span class="fontBlue">报警提醒：</span>不进行弹窗提醒。</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: center;">
      </span>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        loading: true,
        formInline: {
          sosName: '',
        },
        tableData: [],
        pushId: '',
        voiceId: '',
        statusList: [],
        tipBoxVisibile: false,
        sosLevelArr: [{
          value: 1,
          label: '报警提示'
        }, {
          value: 2,
          label: '安全预警'
        }, {
          value: 3,
          label: '严重乘梯安全'
        }],
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
      }
    },
    computed: {
      total() {
        return this.tableData.length
      }
    },
    methods:{
        //获取状态字典
        async getDictionary(code='SOS_TYPE') {
            const res=await this.$http.post(`/api/system/web/dict/dictionary?codes=${code}`)
            if(res.status==200&&res.data.code==200){
                const Arr = res.data.data.SOS_TYPE.filter((item)=>{
                    if(this.adminInfo.userName != 'admin'){
                        return item.value != '21'&&item.value != '35'
                    }else{
                        return item
                    }
                })
                this.statusList=Arr
            }
            this.getTable()
        },
        getStatusText(status){
            const list=this.statusList.filter(item=>item.value==status)
            return list.length>0?list[0].label:status
        },
        getTable(){
            // this.loading = true
            this.$http.post(`/api/system/web/user/getSosConfig`,{type:this.formInline.sosName})
            .then(res=>{
                if(res.status==200&&res.data.code==200){
                    let newData = []
                    if (this.adminInfo.roleId == 99) {
                        newData = res.data.data.filter(item => item.sosType == 5 || item.sosType == 13)
                    } else {
                        newData = res.data.data
                    }
                    if(this.adminInfo.userName != 'admin'){
                      newData = newData.filter(item=> item.sosType != 35&&item.sosType != 21&&item.sosType != 22)
                    }
                    this.tableData = newData
                    sessionStorage.removeItem('sosConfig');
                    sessionStorage.setItem('sosConfig', JSON.stringify(newData))
                }
                this.loading = false
            })
        },
        editSosConfig(data){
            this.$http.post(`/api/system/web/user/editSosConfig`,data)
            .then(res=>{
                if(res.status==200&&res.data.code==200){
                    this.$message({
                        type: 'success',
                        message: "修改成功"
                    })
                    this.getTable()
                }
            })
        },
        pushChange(row){
            this.pushId=row.id
            this.editSosConfig(row)
            this.pushId=''
        },
        voiceChange(row){
            this.voiceId=row.id
            this.editSosConfig(row)
            this.voiceId=''
        },
        levelChange(row){
            this.voiceId=row.id
            const data = row
            if (row.sosLevel == 1) {
              data.voice = 0
            } else {
              data.voice = 1
            }
            this.editSosConfig(data)
            this.voiceId=''
        },
        openTip(){
            this.tipBoxVisibile = true
        }
    },
    created() {
      this.getDictionary()
    }
  }
</script>
<style lang="scss" scoped>
  .fontBlue {
    color: #00f6ff;
  }

  .tipsTableTitle {
    margin-left: 8px;
  }

  .tipsTableTitle:hover {
    cursor: pointer
  }

  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tipsBody {
    height: 60vh;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;

    .tipsBox {
      flex: 1;
      padding: 0 20px;

      .tipsHead {
        border: 1px solid;
        border-radius: 10px;
        margin-bottom: 20px;

        .tipsTitle {
          font-size: 18px;
          text-align: center;
          padding: 15px;
        }

        .tipsTxt {
          font-size: 14px;
          padding: 15px;
          line-height: 26px;
          min-height: 230px;
        }
      }
    }
  }
</style>
