<template>
  <el-dialog title="信号检测终端" v-if="visible" :visible.sync="visible" :close-on-click-modal="false" append-to-body
    @close="close" width="850px">
    <div style="padding: 20px;">
      <el-form label-position="left" :model="forms">
        <el-form-item label="设备号:">
          <el-input placeholder="请输入信号检测终端设备号" style="width: 90%;" v-model="forms.authCode"></el-input>
        </el-form-item>
        <el-form-item label="最新信号强度:">
          <span
            style="color: #FFF;">{{forms.createTime ? '(数据更新时间：' +$formatDate(new Date(forms.createTime),'yyyy-MM-dd HH:mm:ss')+')' : '暂无数据'}}</span>
        </el-form-item>
        <div style="margin-left: 30px;">
          <el-form-item label="移动信号强度:">
            <span style="color: #FFF;display: flex;align-items: center;">
              <el-image v-if="forms.content && forms.content.strength4G[0] != -999"
                :src="require(`@/assets/signalicon/sin${protImg(forms.content.strength4G[0])}.png`)"
                style="width:30px;height:30px;margin-right:20px" />
              {{forms.content ?( forms.content.strength4G[0] != -999 ? '('+Math.floor(forms.content.strength4G[0]) + 'dBm' +')' : '无信号') : '暂无数据'}}
            </span>
          </el-form-item>
          <el-form-item label="电信信号强度:">
            <span style="color: #FFF;display: flex;align-items: center;">
              <el-image v-if="forms.content && forms.content.strength4G[1] != -999"
                :src="require(`@/assets/signalicon/sin${protImg(forms.content.strength4G[1])}.png`)"
                style="width:30px;height:30px;margin-right:20px" />
              {{forms.content ?( forms.content.strength4G[1] != -999 ? '('+Math.floor(forms.content.strength4G[1]) + 'dBm' +')' : '无信号'): '暂无数据'}}
            </span>
          </el-form-item>
          <el-form-item label="联通信号强度:">
            <span style="color: #FFF;display: flex;align-items: center;">
              <el-image v-if="forms.content && forms.content.strength4G[2] != -999"
                :src="require(`@/assets/signalicon/sin${protImg(forms.content.strength4G[2])}.png`)"
                style="width:30px;height:30px;margin-right:20px" />
              {{forms.content ?( forms.content.strength4G[2] != -999 ? '('+Math.floor(forms.content.strength4G[2]) + 'dBm' +')' : '无信号') : '暂无数据'}}
            </span>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="sure">确定</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    formatDate
  } from '@/util';
  export default {
    props: ['propForm'],
    data() {
      return {
        visible: true,
        forms: {
          authCode: ''
        },
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.getInfo()
      })
    },
    methods: {
      protImg(src) {
        let imgindex = 1
        if (src >= -85) {
          imgindex = 1
        } else if (src >= -95 && src < -85) {
          imgindex = 2
        } else if (src >= -105 && src < -95) {
          imgindex = 3
        } else if (src >= -115 && src < -105) {
          imgindex = 4
        } else if (src <= -115) {
          imgindex = 5
        }
        return imgindex
      },
      getInfo() {
        this.$http.get(`/api/ele/web/elevatorInfo/getSignalCodeData?id=${this.propForm.id}`).then((res) => {
          if (res.data.code == 200) {
            this.forms = res.data.data ? JSON.parse(res.data.data) : this.forms
            this.forms.content = this.forms.content ? JSON.parse(this.forms.content) : ''
            this.forms.authCode = this.propForm.signalCode ? this.propForm.signalCode : ''
            console.log(this.forms, 666)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      sure() {
        const data = {
          signalCode: this.forms.authCode,
          id: this.propForm.id
        }
        this.$http.post('/api/ele/web/elevatorInfo/saveSignalCodeInfo', data).then((res) => {
          if (res.data.code == 200) {
            this.$emit('update')
            this.visible = false
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      close() {
        this.visible = false
        this.$emit('signalClose')
      }
    }
  }
</script>

<style>
</style>
