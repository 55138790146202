<template>
	<div>
		<div class="page-top">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯名称</span>
					</span>
					<el-input v-model="formInline.eleName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯注册代码</span>
					</span>
					<el-input v-model="formInline.eleCode" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">负责人</span>
					</span>
					<el-input v-model="formInline.overhaulUser" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">维保公司</span>
					</span>
					<el-input v-model="formInline.maintainUnitName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">状态</span>
					</span>
					<el-select v-model="formInline.overhaulStatus" placeholder="请输入" class="einp" clearable>
						<el-option v-for="item in typeDate.OVERHAUL_STATUS" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onRefer(true)">查询</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
			<div style="display: flex;">
				<el-button v-focus type="success" @click="addDj" size="small">新增</el-button>
				<el-button v-focus type="danger" @click="delAll" size="small">批量删除</el-button>
			</div>
		</div>
		<div>
			<template v-if="dictionarTotal != 0">
				<el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" @selection-change="handleSelectionChange"
					style="width: 100%;margin: 20px 0;" :height="$store.state.tabHeight-80">
					<el-table-column type="selection" width="55px">
					</el-table-column>
					<el-table-column type="index" label="序号" width="50px" />
					<el-table-column align='center' prop="eleName" label="电梯名称" />
					<el-table-column align='center' prop="eleCode" label="电梯注册码" />
					<el-table-column align='center' prop="eleAddress" label="电梯地址" width="200px" />
					<el-table-column align='center' prop="maintainUnitName" label="维保公司" />
					<el-table-column align='center' prop="overhaulUser" label="大修负责人" />
					<el-table-column align='center' prop="planStartTime" label="计划开始时间" />
					<el-table-column align='center' prop="planEndTime" label="计划结束时间" />
					<el-table-column align='center' prop="actualStartTime" label="实际开始时间" />
					<el-table-column align='center' prop="actualEndTime" label="实际结束时间" />
					<el-table-column align='center' label="状态">
						<template slot-scope="scope">
							{{
								scope.row.overhaulStatus == 0 ?"未开始":
								scope.row.overhaulStatus == 1 ?"进行中":"已完成"
							}}
						</template>
					</el-table-column>
					<el-table-column fixed="right" align='center' prop="address" label="操作" width="350px">
						<template slot-scope="scope">
							<el-button v-focus @click="upd(scope.row)" type="primary" size="small">编辑</el-button>
							<el-button v-focus @click="del([scope.row.id])" type="danger" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
					@pagination="contractPage" />
			</template>
			<div class="nullDate" v-else v-loading="tableLoading">
      			<img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
			</div>
		</div>

		<el-dialog :title="tit" v-if="dialogVisible" :visible.sync="dialogVisible" :close-on-click-modal="false"
			width="950px" top="20vh">
			<div style="height: 40vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
				<el-form label-position="right" label-width="130px" ref="contractForm" :model="contractForm"
					:rules="formRules">
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="电梯名称:" prop="eleName">
								<el-input :placeholder="contractForm.eleName || '暂无内容'" disabled v-model="contractForm.eleName"
									class="wid90">
									<el-button slot="append" type="primary" @click="selectElevator()">选择电梯</el-button>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="电梯注册代码:">
								<el-input class="wid90" v-model="contractForm.eleCode" placeholder="请输入"
									:disabled="true" />
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="电梯地址:">
								<el-input class="wid90" v-model="contractForm.eleAddress" placeholder="请输入"
									:disabled="true" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="维保单位:">
								<el-input class="wid90" v-model="contractForm.maintainUnitName" placeholder="请输入"
									:disabled="true" />
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="大修负责人:">
								<el-input class="wid90" v-model="contractForm.overhaulUser" placeholder="请输入" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="大修状态:">
								<el-select v-model="contractForm.overhaulStatus" placeholder="请输入" style="width: 100%">
									<el-option v-for="(item, index) in typeDate.OVERHAUL_STATUS" :key="index"
										:label="item.label" :value="item.value" />
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="物业公司:">
								<el-select @change="propContact" v-model="contractForm.prData" placeholder="请输入"
									style="width: 100%">
									<el-option v-for="(item, index) in wyData" :key="index" :label="item.name+'（'+item.unitCode+'）'"
										:value="item.id+'@@'+item.contact" />
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="物业公司负责人:">
								<!-- <el-input class="wid90" v-model="contractForm.propertyUnitUser" placeholder="请输入" /> -->
								<el-select v-model="contractForm.prData" placeholder="请输入" style="width: 100%"
									:disabled="true">
									<el-option v-for="(item, index) in wyData" :key="index" :label="item.contact"
										:value="item.id+'@@'+item.contact" />
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="计划起止时间:">
								<el-date-picker v-model="contractForm.planStart" style="width: 100%" type="daterange"
									clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
									value-format="yyyy-MM-dd">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="实际起止时间:">
								<el-date-picker v-model="contractForm.actualStart" style="width: 100%" type="daterange"
									clearable range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
									value-format="yyyy-MM-dd">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="检验日期:">
								<el-date-picker v-model="contractForm.checkTime" style="width: 100%" type="date"
									placeholder="请输入" value-format="yyyy-MM-dd HH:mm:ss" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="检验单位:">
								<el-select v-model="contractForm.inspectionUnitId" placeholder="请输入"
									style="width: 100%">
									<el-option v-for="(item, index) in jyData" :key="index" :label="item.name+'（'+item.unitCode+'）'"
										:value="item.id" />
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="鉴定时间:">
								<el-date-picker v-model="contractForm.identificationTime" style="width: 100%"
									type="date" placeholder="请输入" value-format="yyyy-MM-dd HH:mm:ss" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="放款时间:">
								<el-date-picker v-model="contractForm.loanTime" style="width: 100%" type="date"
									placeholder="请输入" value-format="yyyy-MM-dd HH:mm:ss" />
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="放款金额:">
								<el-input class="wid90" v-model="contractForm.loanAmount" placeholder="请输入" />
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="24">
							<el-form-item label="验收文件:">
								<FileUpload :md5s.sync="contractForm.acceptanceFile" :multiple="false" :limit="1"
									:url="url" />
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="24">
							<el-form-item label="过程文件:">
								<FileUpload :md5s.sync="contractForm.processFile" :multiple="false" :limit="1"
									:url="url" />
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="24">
							<el-form-item label="鉴定文件:">
								<FileUpload :md5s.sync="contractForm.identificationFile" :multiple="false" :limit="1"
									:url="url" />
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" type="primary" :loading="loading" @click="dioSub">确定</el-button>
				<el-button size="small" @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
		<SelectElevator v-if="selectOtherDialogVisible" ref="SelectElevator" @transferElevator="selectOtherResult" />
	</div>
</template>
<script>
	import Pagination from '../../../components/Pagination'
	import FileUpload from '../../../components/FileUploadPWP'
	import SelectElevator from './components/selectElevator'
	export default {
		components: {
			Pagination,
			FileUpload,
			SelectElevator,
		},
		data() {
			return {
				formInline: {
					current: 1,
					size: 10,
					overhaulStatus: "2"
				},
				// 表格参数
				contractForm: {},
				tableData: [],
				// dialog参数
				tit: '',
				dialogVisible: false,
				loading: false,
				url: '/api/file/web/uploadByOSS', // 上传地址
				formRules: {
					eleName: [{
						required: true,
						message: '请选择电梯',
						trigger: 'blur'
					}]
				},
				selectOtherDialogVisible: false, // 选择电梯
				delData: [], //多选的数据

				dictionarTotal: 0,

				// 下拉数据
				wyData: [],
				jyData: [],
				typeDate: [],
				tableLoading: true
			}
		},
		methods: {
			propContact(data) {
				this.contractForm.propertyUnitId = data.split("@@")[0]
				this.contractForm.propertyUnitUser = data.split("@@")[1]
			},
			handleSelectionChange(val) {
				this.delData = val;
			},
			delAll() {
				if (this.delData.length > 0) {
					console.log('this.delData', this.delData)
					let arr = []
					this.delData.forEach((qs) => {
						arr.push(qs.id)
					})
					this.del(arr)
				} else {
					this.$message.warning('请选择要删除的记录')
				}
			},
			del(item, isAll) {
				this.$confirm('此操作将永久删除记录，是否继续', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http
						.delete(`/api/ele/web/elevatorOverhaul/delElevatorOverhaul?ids=${item.join(',')}`)
						.then((res) => {
							if (res.data.success) {
								this.delData = []
								this.$refs.multipleTable.clearSelection();
								this.onRefer()
								this.$message.success(res.data.msg)
							} else {
								this.$message.error(res.data.msg)
							}
						})
					console.log(item)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			//
			openFile(url) {
				window.open(url, '_blank');
			},
			// 表格下载
			download(url) {
				this.downloadUrlFile(url)
				// window.open(url, '_blank')
			},
			getFileName(url) {
				var num = url.lastIndexOf('/') + 1
				var fileName = url.substring(num)
				//把参数和文件名分割开
				fileName = decodeURI(fileName.split('?')[0])
				return fileName
			},
			downloadUrlFile(url) {
				url = url.replace(/\\/g, '/')
				const xhr = new XMLHttpRequest()
				xhr.open('GET', url, true)
				xhr.responseType = 'blob'
				//xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
				xhr.onload = () => {
					if (xhr.status === 200) {
						// 获取文件blob数据并保存
						var fileName = this.getFileName(url)
						this.saveAs(xhr.response, fileName)
					}
				}

				xhr.send()
			},
			saveAs(data, name) {
				var urlObject = window.URL || window.webkitURL || window
				var export_blob = new Blob([data])
				var save_link = document.createElementNS(
					'http://www.w3.org/1999/xhtml',
					'a'
				)
				save_link.href = urlObject.createObjectURL(export_blob)
				save_link.download = name
				save_link.click()
			},
			// 分页
			contractPage(data) {
				this.formInline.current = data.page
				this.formInline.size = data.limit
				this.onRefer()
			},
			// 修改
			upd(item) {
				this.tit = '修改大修记录'
				item.planStart = [item.planStartTime, item.planEndTime]
				let obj = JSON.parse(JSON.stringify(item))
				if(obj.propertyUnitId!=''){
					obj.prData = obj.propertyUnitId + "@@" + obj.propertyUnitUser
				}
				obj.planStart = [obj.planStartTime, obj.planEndTime]
				obj.actualStart = [obj.actualStartTime, obj.actualEndTime]
				this.contractForm = JSON.parse(JSON.stringify(obj))
				if (obj.checkTime) {
					this.$set(this.contractForm, "checkTime", new Date(obj.checkTime))
				}
				if (obj.checkTime) {
					this.$set(this.contractForm, "identificationTime", new Date(obj.identificationTime))
				}

				if (obj.checkTime) {
					this.$set(this.contractForm, "loanTime", new Date(obj.loanTime))
				}
				this.dialogVisible = true
				console.log('this.contractForm', this.contractForm)
			},
			// 选择电梯
			selectElevator() {
				// 选择电梯
				console.log('选择电梯')
				this.selectOtherDialogVisible = true
				this.$nextTick(() => {
					this.$refs.SelectElevator.init(this.contractForm.eleData, (refresh) => {
						if (refresh) {}
					})
				})


			},
			// 选择电梯返回
			selectOtherResult(data) {
				console.log('电梯', data)
				this.contractForm.eleData = data
				this.contractForm.eleId = data.id
				this.contractForm.eleCode = data.eleCode
				this.contractForm.eleAddress = data.eleAddress
				this.contractForm.maintainUnitName = data.maintainUnitName
				this.$set(this.contractForm, 'eleName', data.lab)
			},
			// 弹出框提交
			dioSub() {
				this.loading = true
				console.log(this.contractForm, 'this.contractForm');
				this.$refs.contractForm.validate(async (valid) => {
					if (valid) {
						const data = JSON.parse(JSON.stringify(this.contractForm))
						if (this.contractForm.planStart) {
							data.planStartTime = this.contractForm.planStart[0]
							data.planEndTime = this.contractForm.planStart[1]
						}

						if (this.contractForm.actualStart) {
							data.actualStartTime = this.contractForm.actualStart[0]
							data.actualEndTime = this.contractForm.actualStart[1]
						}

						this.$http.post('/api/ele/web/elevatorOverhaul/submitElevatorOverhaul', data).then((
							res) => {
							if (res.data.success) {
								this.dialogVisible = false
								this.onRefer()
								this.$message.success(res.data.msg)
							} else {
								this.$message.error(res.data.msg)
							}
						})
					}
				})
				this.loading = false
			},
			// 搜索
			searchOnRefer() {
				const regExp = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g
				if (regExp.test(this.formInline.code)) {
					this.$message.error('标题仅可输入字母，数值，特殊符号!')
					return
				}
				this.formInline.current = 1
				this.onRefer()
			},
			// 重置
			reset() {

				this.formInline = {
					current: 1,
					size: 10,
				}
				this.onRefer()
			},
			// 获取合同方法
			onRefer(ishm) {
				if (ishm) {
					this.formInline.current = 1
				}
				if (this.formInline.areaCodeArr) {
					this.formInline.areaCodes = null
					if (this.formInline.areaCodeArr.length > 0) {
						let obj = JSON.parse(JSON.stringify(this.formInline.areaCodeArr))
						this.formInline.areaCodes = '*' + obj[obj.length - 1] + '*'
					}
				}
				this.tableLoading = true
				this.$http
					.post("/api/ele/web/elevatorOverhaul/getElevatorOverhaulPage", this.formInline)
					.then((res) => {

						if (res.data.success) {
							this.tableData = res.data.data.records
							this.dictionarTotal = res.data.data.total
							this.$nextTick(() => {
								if (this.unitTotal > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
						this.tableLoading = false
					})
			},
			addDj() {
				this.tit = '新增大修记录'
				this.dialogVisible = true
				this.contractForm = {}
			},
		},
		created() {
			this.onRefer()


			// 物业公司 /api/system/web/org/list type: "TENEMENT_UNIT"
			let dataa = {
				current: 1,
				size: 9999,
				type: "TENEMENT_UNIT"
			}
			this.$http
				.post(`/api/system/web/org/list`, dataa)
				.then((res) => {
					if (res.data.success) {
						this.wyData = res.data.data.records
					}
				})
			// 检验单位 /api/system/web/org/list type: "CHECK_UNIT"
			let datab = {
				current: 1,
				size: 9999,
				type: "CHECK_UNIT"
			}
			this.$http
				.post(`/api/system/web/org/list`, datab)
				.then((res) => {
					if (res.data.success) {
						this.jyData = res.data.data.records
					}
				})

			// 大修状态
			this.$http
				.post(`/api/system/web/dict/dictionary?codes=OVERHAUL_STATUS`)
				.then((res) => {
					if (res.data.success) {
						this.typeDate = res.data.data
					}
				})

		}
	}
</script>
<style lang="scss" scoped>
	.searchBtn {
		margin-left: 15px;
	}

	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.txtColor0 {
		color: #909399;
	}

	.txtColor1 {
		color: #409eff;
	}

	.txtColor2 {
		color: #f56c6c;
	}
</style>
