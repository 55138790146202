<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">合同编号</span>
          </span>
          <el-input class="einp" v-model="formInline.code" placeholder="请输入" clearable></el-input>
        </el-form-item>

        <el-form-item>
          <span slot="label">
            <span class="lab-span">物业单位</span>
          </span>
          <el-select v-model="formInline.propertyUnitId" placeholder="请输入" clearable filterable>
            <el-option v-for="(item, index) in propertySearch" :key="index" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>

        <el-form-item>
          <span slot="label">
            <span class="lab-span">维保单位</span>
          </span>
          <el-select v-model="formInline.maintainUnitId" placeholder="请输入" clearable filterable>
            <el-option v-for="(item, index) in maintainSearch" :key="index" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">状态</span>
          </span>
          <el-select v-model="formInline.status" placeholder="请输入" class="einp" clearable>
            <el-option v-for="item in statusArr" :key="item.val" :label="item.lab" :value="item.val">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">合同类型</span>
          </span>
          <el-select v-model="formInline.type" placeholder="请输入" class="einp" clearable>
            <el-option v-for="item in contractArr" :key="item.val" :label="item.lab" :value="item.val">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">创建时间</span>
          </span>
          <el-date-picker v-model="formInline.time" type="date" placeholder="选择日期" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-button v-focus type="success" size="small" @click="action('add', {})">新增</el-button>
    <div v-if="dictionarTotal != 0">
      <el-table :data="tableData" ref="multipleTable" v-loading="loading"
        style="width: 100%; margin-top: 20px; margin-bottom: 20px" max-height="550">
        <el-table-column fixed="left" type="index" label="序号" width="px" />
        <el-table-column align="center" prop="code" label="合同编号" width="230px" />
        <el-table-column align="center" label="合同类型">
          <template slot-scope="scope">
            {{ scope.row.type == 1 ? '双方合同' : '三方合同' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="propertyUnitName" label="物业单位" width="230px" />
        <el-table-column align="center" prop="maintainUnitName" label="维保单位" width="230px" />
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            {{( scope.row.status && statusArr.find(item => item.val == scope.row.status)?.lab) ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="startTime" label="合同开始日期" width="180px">
          <template slot-scope="scope">
            {{ scope.row.startTime ? scope.row.startTime.split(' ')[0] : '-' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="endTime" label="合同结束日期" width="180px">
          <template slot-scope="scope">
            {{ scope.row.endTime ? scope.row.endTime.split(' ')[0] : '-' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="contractTime" label="合同时长(年)" width="150px" />
        <el-table-column align="center" prop="eleNum" label="电梯数量(台)" width="150px" />
        <el-table-column align="center" label="维保类型" width="180px">
          <template slot-scope="scope">
            {{ scope.row.periodicMaintainStatus == 1 ? '定期维保' : ' ' }}
            {{ scope.row.periodicMaintainStatus == 1 && scope.row.demandMaintainStatus == 1 ? '，' : ''}}
            {{ scope.row.demandMaintainStatus == 1 ? '按需维保' : ' ' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="预付方式" width="150px">
          <template slot-scope="scope">
            {{ scope.row.eleMaintainPaymentDataVo.properPrepayType == 1 ? '按月预存' : (
                scope.row.eleMaintainPaymentDataVo.properPrepayType == 2 ? '按季度预存' : '按年预存'
            ) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="结算方式" width="180px">
          <template slot-scope="scope">
            <!-- 定期 -->
            <span v-if="scope.row.periodicMaintainStatus == 1">
              {{ scope.row.eleMaintainPaymentDataVo.periodicMaintainSettleType == 1 ? '按月结算' : (
                    scope.row.eleMaintainPaymentDataVo.periodicMaintainSettleType == 2 ? '按季度结算' : '按年结算'
                ) }}
            </span>
            <span v-if="scope.row.demandMaintainStatus == 1">
              {{ scope.row.periodicMaintainStatus && scope.row.eleMaintainPaymentDataVo.demandMaintainSettleType ? '，' : ''}}
              <!-- 按需 -->
              {{ scope.row.eleMaintainPaymentDataVo.demandMaintainSettleType == 1 ? '按次结算' :  '按年结算'}}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否指定维保公司" width="180px">
          <template slot-scope="scope">
            {{ scope.row.fixedMaintainUnit == 0 ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="结算状态" width="150px">
          <template slot-scope="scope">
            {{ scope.row.settlementStatus == 1 ? '已结算' : '未结算' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createTime" label="创建时间" width="150px" />
        <el-table-column align="center" prop="updateTime" label="更新时间" width="150px" />
        <el-table-column fixed="right" align="center" label="操作" width="400px">
          <template slot-scope="scope">
            <el-button @click="action('check', scope.row)" type="warning" size="small">查看</el-button>
            <el-button
              v-if="scope.row.maintainTaskInitStatus == 0 && scope.row.status == 3 && adminInfo.id == scope.row.createUser"
              @click="generateTasks(scope.row.id)" type="primary" size="small">重新生成维保任务</el-button>
            <el-button v-if="scope.row.status == 10" @click="examine(scope.row.id)" type="primary"
              size="small">提交审核</el-button>
            <el-button v-focus v-if="[2, 10].includes(scope.row.status)" @click="action('edit', scope.row)"
              type="primary" size="small">编辑</el-button>
            <el-button v-focus
              v-if="((scope.row.maintainCheckStatus == 0 && adminInfo.roleId == 5) || (scope.row.propertyCheckStatus == 0 && adminInfo.roleId == 4)) && scope.row.status == 1"
              @click="confirm(scope.row)" type="danger" size="small">确认</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="dictionarPage" />
    </div>
    <div class="nullDate" v-else v-loading="loading">
      <img src="@/assets/nullDate.png" v-if="!loading" style="width: 260px" />
    </div>
    <ContractDialog v-if="contractDialogVisible" ref="ContractDialog" :propertyUnit="propertyUnit"
      :maintainUnit="maintainUnit" :contractdata="contractdata" :type="actionType" @close="ContractDialogClose" />
    <ConfirmDialog v-if="confirmDialogVisible" ref="ConfirmDialog" :contractId="contractId"
      @close="confirmDialogClose" />
  </div>
</template>
<script>
  // 页面对应的type
  import Pagination from "@/components/Pagination";
  import ContractDialog from './components/contractDialog.vue';
  import ConfirmDialog from './components/confirmDialog.vue';
  export default {
    components: {
      Pagination,
      ContractDialog,
      ConfirmDialog
    },
    data() {
      return {
        // 表格参数
        loading: true,
        formInline: {
          current: 1,
          size: 10,
        },
        tableData: [],
        // 分页参数
        dictionarTotal: 0,

        maintainUnit: [], // 维保单位
        propertyUnit: [], // 物业单位

        maintainSearch: [], // 维保单位搜索
        propertySearch: [], // 物业单位搜索
        // 状态
        statusArr: [{
            val: 10,
            lab: "草稿中"
          },
          {
            val: 1,
            lab: "待审核"
          },
          {
            val: 4,
            lab: "待生效"
          },
          {
            val: 3,
            lab: "生效中"
          },
          {
            val: 5,
            lab: "已完成"
          },
          {
            val: 2,
            lab: "不通过"
          }
        ],
        contractArr: [{
            val: 0,
            lab: "三方合同"
          },
          // { val: 1, lab: "双方合同" }
        ],
        actionType: '',
        contractdata: null,
        contractDialogVisible: false,

        confirmDialogVisible: false,
        contractId: '',
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo'))
      };
    },
    methods: {
      // 重置按钮事件
      reset() {
        this.formInline = {
          current: 1,
          size: 10,
        };
        this.onRefer();
      },
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page;
        this.formInline.size = data.limit;
        this.onRefer();
      },
      // 表格方法
      onRefer(ishm) {
        console.log(this.formInline, "this.formInline");
        if (ishm) {
          this.formInline.current = 1;
        }
        if (this.formInline.time) {
          this.formInline.createTime = this.formInline.time.getTime();
        }
        this.$http
          .post("/api/ele/web/maintainContract/getPage", this.formInline)
          .then((res) => {
            if (res.data.success) {
              this.tableData = res.data.data.records;
              this.dictionarTotal = res.data.data.total;
              this.$nextTick(() => {
                if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              });
            } else {
              this.$message.error(res.data.msg);
            }
            this.loading = false
          });
      },
      // 生成维保任务
      generateTasks(id) {
        this.$confirm('是否确认此次操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http
            .get(`/api/ele/web/maintainContract/initMaintainTask?id=${id}`)
            .then((res) => {
              if (res.data.success) {
                this.onRefer()
              } else {
                this.$message.error(res.data.msg);
              }
            });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消提交'
          })
        });
      },
      // 提交审核
      examine(id) {
        this.$confirm('是否确认此次操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http
            .get(`/api/ele/web/maintainContract/checkStatus?status=0&id=${id}`)
            .then((res) => {
              if (res.data.success) {
                this.onRefer()
              } else {
                this.$message.error(res.data.msg);
              }
            });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消提交'
          })
        });
      },

      // 操作
      action(type, data) {
        this.actionType = type
        this.contractDialogVisible = true
        this.contractdata = data
      },
      ContractDialogClose() {
        this.contractDialogVisible = false
        this.onRefer()
      },

      confirm(data) {
        this.contractId = data.id
        this.confirmDialogVisible = true
      },
      confirmDialogClose() {
        this.confirmDialogVisible = false
        this.onRefer()
      },

      //获取维保，物业单位查询
      getUnitsearch(item) {
        const data = {
          type: item,
        };
        this.$http.post("/api/system/web/org/getRelevanceOrgList", data).then((res) => {
          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res;

            switch (item) {
              case "MAINTAIN_UNIT":
                this.maintainSearch = data ?? [];
                break;
              case "TENEMENT_UNIT":
                this.propertySearch = data ?? [];
                break;
            }
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },


      // 获取维保，物业单位
      getUnit(item) {
        const data = {
          current: 1,
          size: 10000,
          type: item,
        };
        this.$http.post("/api/system/web/org/list", data).then((res) => {
          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res;

            switch (item) {
              case "MAINTAIN_UNIT":
                this.maintainUnit = data.records ?? [];
                break;
              case "TENEMENT_UNIT":
                this.propertyUnit = data.records ?? [];
                break;
            }
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
    },
    created() {
      this.getUnit("MAINTAIN_UNIT");
      this.getUnit("TENEMENT_UNIT");
      this.getUnitsearch('MAINTAIN_UNIT')
      this.getUnitsearch('TENEMENT_UNIT')
      this.onRefer();
    },
  };
</script>
<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-top {
    width: 100%;
    margin-bottom: 20px;
  }

  .page-bom {}
</style>
