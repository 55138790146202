<template>
  <div>
    <el-form :inline="true" class="el-form-style" :model="searchForm">
      <el-form-item label="人员名称">
        <el-input placeholder="请输入" v-model="searchForm.userName" clearable></el-input>
      </el-form-item>
      <el-form-item label="角色">
        <el-select v-model="searchForm.userType" placeholder="请输入" filterable clearable>
          <el-option v-for="item in roleData" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属单位">
        <el-input placeholder="请输入" v-model="searchForm.orgName" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="checkInfo">查询</el-button>
        <el-button type="warning" @click="reseat">重置</el-button>
      </el-form-item>
    </el-form>
    <template v-if="noticetotal != 0">
      <el-table :data="tableData" v-loading="tableLoading" ref="multipleTable" :height="$store.state.tabHeight-50"
        style="width:100%; margin: 20px 0;">
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="人员名称" align="center" prop="name"></el-table-column>
        <el-table-column label="角色" align="center" prop="type">
          <template slot-scope="scope">
            {{getTypeName(scope.row.type.split(','))}}
          </template>
        </el-table-column>
        <el-table-column label="联系方式" align="center" prop="contactWay"></el-table-column>
        <el-table-column label="证书编号" align="center" prop="certificateCode"></el-table-column>
        <el-table-column label="所属单位" align="center" prop="orgName"></el-table-column>
        <el-table-column label="证书有效期" align="center">
          <template slot-scope="scope">
            {{scope.row.certificateEndTime ? scope.row.certificateEndTime : ''}} -
            {{scope.row.certificateStartTime ? scope.row.certificateStartTime : ''}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="150px">
          <template slot-scope="scope">
            <!-- v-if="scope.row.roleNames == '电梯安全员'" -->
            <el-button type="primary" v-focus v-if=" scope.row.type.includes('TENEMENT_UNIT')" size="small" @click="checkApp(scope.row)">APP账号</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="noticetotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
        @pagination=" changPage" />
    </template>

    <div class="nullDate" v-else v-loading="tableLoading">
      <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
    </div>
    <el-dialog v-if="appVisiable" title="APP账号" :visible.sync="appVisiable" :close-on-click-modal="false" append-to-body
      width="700px" @close="appVisiable = false">
      <el-form label-width="130px">
        <el-form-item label="人员名称">
          <el-input style="width: 90%;" v-model="appInfo.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="是否允许登录">
          <el-radio v-model="appInfo.isLogin" :label="0" disabled>是</el-radio>
          <el-radio v-model="appInfo.isLogin" :label="1" disabled>否</el-radio>
        </el-form-item>
        <el-form-item label="APP名称">
          <el-input style="width: 90%;" v-model="appInfo.account" disabled></el-input>
        </el-form-item>
        <el-form-item label="APP登录密码">
          <el-input style="width: 90%;" v-model="appInfo.oriPassword" disabled></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="appVisiable = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '../../../../components/Pagination.vue'
  export default {
    components: {
      Pagination
    },
    data() {
      return {
        loading: true,
        tableData: [],
        searchCallback: null,
        noticetotal: 0,
        checkFrom: {
          current: 1,
          size: 10,
        },
        appVisiable: false,
        appInfo: {},
        searchForm: {
          current: 1,
          size: 10,
        },
        tableLoading: true,
        roleData: [], // 角色
      }
    },
    methods: {
      // 获取字典
      getNotice(code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.roleData = this.$store.state.isWBJB ? data.RELATED_CODES.filter(item=>item.value == 'MAINTAIN_UNIT' || item.value == 'TENEMENT_UNIT') : data.RELATED_CODES
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      getTypeName(obj){
        let tn = []
        this.roleData.forEach(qs => {
          obj.forEach(qs2 => {
            if (qs.value == qs2) {
              tn.push(qs.label)
              return
            }
          })
        })
        return tn.join(',')
      },
      //app账号
      checkApp(data) {
        console.log(data, 666)
        this.appVisiable = true
        this.appInfo = data
      },
      //初始化
      init(callback) {
        this.searchCallback = callback;
        this.loading = false;
        // this.$nextTick(()=>{
        //   this.onRefer()
        // })
      },
      //分页
      changPage(data) {
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer();
      },
      //重置
      reseat() {
        this.searchForm = {
          current: 1,
          size: 10
        }
        this.onRefer();
      },
      //查询
      checkInfo() {
        this.searchForm.current = 1
        this.onRefer();
      },
      //信息
      onRefer() {
        this.tableLoading = true
        this.$http.post('/api/ele/web/elevatorInfo/eleAssociatedPersonnel', this.searchForm)
          .then((res) => {
            console.log('人员', res.data)
            if (res.data.success) {
              this.tableData = res.data.data.records ?? []
              this.noticetotal = res.data.data.total ?? 0
              this.$nextTick(() => {
                if (this.noticetotal > 0) {
                  this.$refs.multipleTable.bodyWrapper.scrolltop = 0
                }
              })
            } else {
              this.$message.error(res.data.data.msg)
            }
            this.tableLoading = false
          })
      }
    },
    created() {
      this.onRefer();
      this.getNotice(['RELATED_CODES'])
    }
  }
</script>
<style scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
