<template>
  <el-dialog :close-on-click-modal="false" :visible.sync="dioDetails" width="1200px" top="15vh" @close="closeDialog">
    <div class="tabBut">
      <el-button @click="detailsItem.seleId = 1" :type="1 == detailsItem.seleId ? 'primary' : ''">报警消息
      </el-button>
      <el-button
        v-show="dioDetailsItem && dioDetailsItem.sosTypeName == '安全回路报警' || dioDetailsItem.sosTypeName == '电梯运行异常告警'"
        @click="detailsItem.seleId = 2" :type="2 == detailsItem.seleId ? 'primary' : ''">运行参数</el-button>
      <el-button @click="detailsItem.seleId = 3" :type="3 == detailsItem.seleId ? 'primary' : ''">处置流程</el-button>
    </div>
    <div style="height: 58vh; margin-top: 10px" class="scrollElement"
      :class="[detailsItem.seleId == 1 ? 'yeScroll' : 'noScroll']">
      <alarmMessage v-if="detailsItem.seleId == 1" :dioData="dioDetailsItem" />
      <operational v-else-if="detailsItem.seleId == 2" :dioData="dioDetailsItem" />
      <process v-else :dioData="dioDetailsItem" />
    </div>
    <span slot="footer" class="dialog-footer" style="display: flex; align-items: center; justify-content: center">
      <div v-if="detailsItem.seleId == 1 && dioDetailsItem && dioDetailsItem.submitType === 0">
        <template v-if="
        	dioDetailsItem.sosType == 95 ||
        	dioDetailsItem.sosType == 0 ||
        	dioDetailsItem.sosType == 10 ||
        	dioDetailsItem.sosType == 7 ||
        	dioDetailsItem.sosType == 3 ||
          dioDetailsItem.sosType == 333 ||
        	dioDetailsItem.sosType == 4 ||
        	dioDetailsItem.sosType == 5 ||
        	dioDetailsItem.sosType == 11 ||
        	dioDetailsItem.sosType == 12 ||
        	dioDetailsItem.sosType == 13 ||
          dioDetailsItem.sosType == 96 ||
          dioDetailsItem.sosType == 1 ||
          dioDetailsItem.sosType == 2 ||
          dioDetailsItem.sosType == 14 ||
          dioDetailsItem.sosType == 999 ||
          dioDetailsItem.sosType == 98 ||
          dioDetailsItem.sosType == 21 ||
          dioDetailsItem.sosType == 20 ||
          dioDetailsItem.sosType == 19 ||
          dioDetailsItem.sosType == 15 ||
          dioDetailsItem.sosType == 109 ||
            dioDetailsItem.sosType == 22
        ">
          <el-button :disabled="disabled" size="mini" type="primary" @click="sosClick(1)"
            v-if="dioDetailsItem.sosType != 109&&dioDetailsItem.sosType != 15&&dioDetailsItem.sosType != 13&&dioDetailsItem.sosType != 98">
            <span>人为触发(无故障)</span>
            <p style="margin: 10px 0 0 0;">确认关闭</p>
          </el-button>
          <el-button :disabled="disabled" size="mini" type="primary" @click="sosClick(6)"
            v-if="dioDetailsItem.sosType == 13">
            <span>确认</span>
            <p style="margin: 10px 0 0 0;">正常报警非误报</p>
          </el-button>
          <el-button size="mini" type="info"
            v-if="dioDetailsItem.sosType != 13&&dioDetailsItem.sosType != 22&&dioDetailsItem.sosType != 109&& dioDetailsItem.sosType != 21 &&dioDetailsItem.sosType != 15 && dioDetailsItem.sosType != 98"
            @click="sosClick(2)" :disabled="disabled">
            <span>电梯故障(无困人)</span>
            <p style="margin: 10px 0 0 0;">派发维修工单</p>
          </el-button>
          <el-button size="mini" type="warning"
            v-if="dioDetailsItem.sosType != 22&&dioDetailsItem.sosType != 109&& dioDetailsItem.sosType != 21 &&dioDetailsItem.sosType != 19 &&dioDetailsItem.sosType != 20 && dioDetailsItem.sosType != 15&&dioDetailsItem.sosType != 13&& dioDetailsItem.sosType != 96"
            @click="sosClick(3)" :disabled="disabled">
            <span>困人</span>
            <p style="margin: 10px 0 0 0;">立即派发救援工单</p>
          </el-button>
          <el-button class="errorSos" size="mini"
            v-if="dioDetailsItem.sosType != 109&&dioDetailsItem.sosType != 15&& dioDetailsItem.sosType != 98"
            @click="sosClick(5)" :disabled="disabled">
            <span>误报</span>
            <p style="margin: 10px 0 0 0;">设备产生错误报警</p>
          </el-button>
        </template>
      </div>
    </span>
  </el-dialog>
</template>

<script>
  import process from '../../bjgl/bjjl/components/process'
  import operational from '../../bjgl/bjjl/components/operational'
  import alarmMessage from '../../bjgl/bjjl/components/alarmMessage'
  export default {
    components: {
      process,
      operational,
      alarmMessage
    },
    data() {
      return {
        dioDetails: false,
        addBrandCallback: null, // 弹窗回调

        // 报警详情
        detailsItem: {
          seleId: 1,
          seleArr: [{
              value: 1,
              label: "报警消息"
            },
            {
              value: 2,
              label: "运行参数"
            },
            {
              value: 3,
              label: "处置流程"
            },
          ],
        },
        stateList: [{
            value: 1,
            label: '待处理'
          },
          {
            value: 2,
            label: '已处理'
          },
          {
            value: 3,
            label: '进行中'
          },
          {
            value: 4,
            label: '已完成'
          }
        ],
        dioDetailsItem: null,
        disabled: false
      };
    },

    mounted() {},
    methods: {
      init(data, callback) {
        this.addBrandCallback = callback;
        this.$nextTick(() => {
          this.getPersonnel(data);
        });
      },
      closeDialog() {
        this.dioDetails = false;
        this.$emit('closeSosDialog')
      },
      getPersonnel(data) {

        this.$http
          .post("/api/ele/web/sos/getInfo", {
            id: data.id,
            sosTime: data.sosTime ? data.sosTime : ''
          })
          .then((res) => {
            if (res.data.success) {
              res.data.data.sosVideo.forEach((qs) => {
                let url = qs.videoUrl.split("?");
                qs.videoUrl = url[0];
                qs.videoName = url[1].split("&")[0].split("=")[1];
              });
              res.data.data.sosTypeName = data.sosType == -1 ? data.sosText != "" ? data.sosText : "未知" : data
                .sosTypeName != "" ? data.sosTypeName : "未知";
              res.data.data.sosStatusName = this.stateList.find((item) => item.value === res.data.data.sosStatus) ?
                this.stateList.find((item) => item.value === res.data.data.sosStatus).label : "";
              res.data.data.speed = res.data.data.speed !== "" ? parseFloat(res.data.data.speed) : 0;
              res.data.data.speedarr = res.data.data.speedRecord.split(",");

              this.detailsItem.seleId = 1;
              this.dioDetailsItem = res.data.data;
              this.dioDetailsItem.emergencyNames = res.data.data.emergencyRescueUserNames.split(";");
              this.dioDetailsItem.maintainNames = res.data.data.maintainUsers.split(";");
              this.dioDetailsItem.propertyNames = res.data.data.propertyUsers.split(";");
              this.dioDetails = true;
              this.dioDetails = true;
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },

      // 详情操作按钮
      sosClick(type) {
        this.disabled = true;
        if (this.dioDetailsItem.submitType === 0) {
          this.dioDetailsItem.sosStatus = 2;
          this.$http
            .post("/api/ele/app/sos/submit", {
              id: this.dioDetailsItem.id,
              submitType: type,
              sosTime: this.dioDetailsItem.sosTime
            })
            .then((res) => {
              if (res.data.success) {
                if (type == 5) {
                  this.$confirm('确认误报成功，是否删除该报警误报记录？', '提示', {
                    cancelButtonText: '取消',
                    confirmButtonText: '确认',
                    type: 'warning'
                  }).then(() => {
                    this.$http
                      .post("/api/ele/web/sos/removeSosInfo", {
                        id: this.dioDetailsItem.id,
                        sosTime: this.dioDetailsItem.sosTime,
                      }).then((res) => {
                        if (res.data.code == 200) {
                          this.closeDialog()
                          this.$message.success(res.data.msg)
                        } else {
                          this.$message.error(res.data.msg)
                        }
                      })
                  }).catch(() => {
                    this.closeDialog()
                  })
                } else {
                  this.$message.success(res.data.msg)
                }
                // this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
              this.onRefer();

              this.showDetails(
                this.tableData.find((item) => item.id === this.dioDetailsItem.id)
              );
            });
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .tabBut {
    width: 80%;
    margin-left: 10%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .errorSos {
    background: #00dcff;
    border-color: #00dcff;
    color: #fff
  }
</style>
