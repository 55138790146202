<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" ref="searchForm" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">生效单位名称</span>
          </span>
          <el-input v-model="searchForm.orgName" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button v-focus type="success" size="small" @click="addDj">新增</el-button>
      <template v-if="tableData != 0">
        <el-table ref="multipleTable" v-loading="tableLoading" :data="tableData" :height="$store.state.tabHeight"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px">
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align="center" prop="orgName" label="生效单位名称" width="250px" />
          <el-table-column align="center" prop="verificationModeStr" label="验证方式" width="150px" />
          <el-table-column align="center" prop="signInStr" label="签到" />
          <el-table-column align="center" prop="signOutStr" label="签退" />
          <el-table-column align="center" prop="verifaceStr" label="人脸识别" />
          <el-table-column align="center" prop="signatureStr" label="维保签字确认" min-width="130px" />

          <el-table-column align="center" prop="maintainPersonnelType" label="维保人数要求" min-width="130px">
            <template slot-scope="scope">
              {{scope.row.maintainPersonnelType}} 人
            </template>
          </el-table-column>
          <el-table-column align="center" prop="signOutStr" label="提交位置验证" min-width="130px">
            <template slot-scope="scope">
              {{scope.row.submitType == 1 ? '开启' : '关闭'}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="verifaceStr" label="维保时长要求" min-width="130px">
            <template slot-scope="scope">
              {{scope.row.maintainDurationType == 1 ? '大于等于30分钟' : '不限制'}}
            </template>
          </el-table-column>

          <el-table-column align="center" prop="maintainRemindStr" label="维保提醒" />
          <el-table-column align="center" prop="maintainRemindModeStr" label="提醒方式" width="200px" />
          <el-table-column align="center" prop="createUserName" label="创建人" width="150px" />
          <el-table-column fixed="right" align="center" prop="address" label="操作" width="300px">
            <template slot-scope="scope">
              <el-button v-focus @click="edit(scope.row, 'check')" type="primary" size="small">查看
              </el-button>
              <el-button v-focus @click="edit(scope.row, 'edit')" type="warning" size="small">编辑
              </el-button>
              <el-button v-focus @click="del(scope.row)" type="danger" size="small">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="maintenRuleTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
          @pagination="maintenRulePage" />
      </template>

      <div class="nullDate" v-else v-loading="tableLoading">
        <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
      </div>
    </div>

    <el-dialog :title="tit" v-if="dialogVisible" :visible.sync="dialogVisible" :close-on-click-modal="false"
      width="950px" top="20vh">
      <div style="height: 40vh; overflow-y: scroll; padding-right: 40px" class="scrollElement">
        <!-- {{editShow}} -->
        <el-form label-position="right" label-width="140px" ref="maintenRuleForm" :model="maintenRuleForm"
          :rules="formRules">
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="生效单位:" prop="unitName">
                <el-input :placeholder="maintenRuleForm.unitName || '暂无内容'" v-model="maintenRuleForm.unitName"
                  class="input-with-select wid90" disabled>
                  <el-button :disabled="maintenRuleFormDisabled|| !editShow || dialogType == 'check'" slot="append" type="primary"
                    @click="selectUnit()">选择生效单位</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="签到:" prop="signIn">
                <el-select v-model="maintenRuleForm.signIn" :disabled="dialogType == 'check' || !editShow" placeholder="请输入"
                  style="width: 100%">
                  <el-option label="开启" :value="1" />
                  <el-option label="关闭" :value="0" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="签退:" prop="signOut">
                <el-select v-model="maintenRuleForm.signOut" :disabled="dialogType == 'check' || !editShow" placeholder="请输入"
                  style="width: 100%">
                  <el-option label="开启" :value="1" />
                  <el-option label="关闭" :value="0" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保签字确认:" prop="signature">
                <el-select v-model="maintenRuleForm.signature" :disabled="dialogType == 'check' || !editShow" placeholder="请输入"
                  style="width: 100%">
                  <el-option label="开启" :value="1" />
                  <el-option label="关闭" :value="0" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="验证方式:" prop="verificationMode">
                <el-select multiple v-model="maintenRuleForm.verificationMode" :disabled="dialogType == 'check' || !editShow"
                  placeholder="请输入" style="width: 100%">
                  <el-option label="扫码" :value="2" />
                  <el-option label="GPS定位" :value="3" />
                  <el-option label="安全帽签到" :value="4" />
                  <el-option label="拍照" :value="5" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="超期后可维保:" prop="overdueOperation">
                <el-select v-model="maintenRuleForm.overdueOperation" :disabled="dialogType == 'check' || !editShow"
                  placeholder="请输入" style="width: 100%">
                  <el-option label="不可维保" :value="0"/>
                  <el-option label="可超期1天" :value="1"/>
                  <el-option label="可超期2天" :value="2"/>
                  <el-option label="可超期3天" :value="3"/>
                  <el-option label="可超期5天" :value="5"/>
                  <el-option label="可超期不限制" :value="100"/>
                  <!-- <el-option label="是" :value="1" />
                  <el-option label="否" :value="0" /> -->
                </el-select>
                <el-tooltip class="item" effect="dark" placement="top-end">
                  <div slot="content">选择可超期1天，则计划时间第二天可维<br/>保，第三天则不可维保。</div>
                  <i class="el-icon-warning-outline" style="position: absolute;top: 10px;font-size: 18px;margin-left: 10px"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="提前维保:" prop="dayAdvance">
                <el-select v-model="maintenRuleForm.dayAdvance" :disabled="dialogType == 'check' || !editShow" placeholder="请选择"
                  style="width: 100%">
                  <el-option label="不可提前维保" :value="0" />
                  <el-option label="可提前1天" :value="1" />
                  <el-option label="可提前2天" :value="2" />
                  <el-option label="可提前3天" :value="3" />
                  <el-option label="可提前5天" :value="5" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="提前维保更新计划:" prop="isMoveForward">
                <el-select v-model="maintenRuleForm.isMoveForward" :disabled="dialogType == 'check' || !editShow" placeholder="请选择"
                  style="width: 100%">
                  <el-option label="后续任务不前移" :value="0" />
                  <el-option label="后续任务前移" :value="1" />
                </el-select>
                <el-tooltip class="item" effect="dark" placement="top-end">
                  <div slot="content">选择后续任务前移时，提前维保完成物业签字后，将<br/>自动更新维保计划，所有后续维保任务前移相应的天<br/>数。若物业签字延迟过大将不进行前移。</div>
                  <i class="el-icon-warning-outline" style="position: absolute;top: 10px;font-size: 18px;margin-left: 10px"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="维保提醒:" prop="maintainRemind" >
                <el-select multiple v-model="maintenRuleForm.maintainRemind" :disabled="dialogType == 'check' || !editShow"
                  placeholder="请输入" style="width: 100%">
                  <el-option label="APP" :value="1" />
                  <el-option label="短信" :value="2" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保签到半径:" prop="radius">
                <el-input :placeholder="maintenRuleForm.radius || '请输入维保签到半径'" :disabled="dialogType == 'check'"
                  v-model="maintenRuleForm.radius" class="input-with-select wid90">
                  <template slot="append">
                    米
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="人脸识别:" prop="veriface">
                <el-select v-model="maintenRuleForm.veriface" :disabled="dialogType == 'check'" style="width: 100%">
                  <el-option :key="1" :value="1" label="开启"></el-option>
                  <el-option :key="2" :value="0" label="关闭"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保人数要求:" prop="maintainPersonnelType">
                <el-select v-model="maintenRuleForm.maintainPersonnelType" :disabled="dialogType == 'check' || !editShow"
                  style="width: 100%" @change="changePerson">
                  <el-option :key="1" :value="1" label="1人"></el-option>
                  <el-option :key="2" :value="2" label="2人"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="提交位置验证:" prop="submitType">
                <el-select v-model="maintenRuleForm.submitType" :disabled="dialogType == 'check' || maintainPersonn || !editShow"
                  style="width: 100%">
                  <el-option :key="1" :value="1" label="开启"></el-option>
                  <el-option :key="2" :value="0" label="关闭"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保时长要求:" prop="maintainDurationType">
                <el-select v-model="maintenRuleForm.maintainDurationType"
                  :disabled="dialogType == 'check' || maintainPersonn || !editShow "  style="width: 100%">
                  <el-option :key="1" :value="0" label="不限制"></el-option>
                  <el-option :key="2" :value="1" label="大于等于30分钟"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保项目扫码:" prop="isSweep">
                <el-select v-model="maintenRuleForm.isSweep" :disabled="dialogType == 'check' || !editShow" placeholder="请选择"
                  style="width: 100%">
                  <el-option label="无需扫码" :value="1" />
                  <el-option label="机房、底坑、轿顶扫码" :value="2" />
                </el-select>
                <el-tooltip class="item" effect="dark" placement="top-end">
                  <div slot="content">开启维保项目扫码，需要提前在需扫码的<br/>位置（如轿顶、底坑、机房）张贴对应位<br/>置的电梯标志牌。维保时，扫码后才能提<br/>交。</div>
                  <i class="el-icon-warning-outline" style="position: absolute;top: 10px;font-size: 18px;margin-left: 10px"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="维保提醒时间:">
                <el-table :data="unitList" style="width: 100%">
                  <el-table-column align="center" prop="label" label="提示周期" width="180">
                  </el-table-column>
                  <el-table-column align="center" label="时间" width="350">
                    <template slot-scope="scope">
                      <el-time-select disabled v-model="scope.row.time" :picker-options="{
                          start: '07:00',
                          step: '00:15',
                          end: '07:00',
                        }" placeholder="选择时间" />
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="是否启用">
                    <template slot-scope="scope">
                      <el-switch v-model="scope.row.switch" :disabled="dialogType == 'check'" :active-value="1"
                        :inactive-value="0" @change="changeSwitch(scope.row, scope.$index)">
                      </el-switch>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>


      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" :loading="loading" v-if="dialogType != 'check'"
          @click="dioSub(maintenRuleFormDisabled ? 'edit' : 'add')">确 定</el-button>
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <SelectUnit v-if="selectOtherDialogVisible" ref="SelectUnit" @transferUnit="selectOtherResult" />
  </div>
</template>
<script>
  import Pagination from '../../../components/Pagination'
  import SelectUnit from './components/selectUnit'
  var validateTime = (rule, value, callback) => {
    let operation = /^(0|[1-9][0-9]*)$/;
    inpYz(rule, value, callback, false, operation, "只能输入0或正整数");
  };
  export default {
    components: {
      Pagination,
      SelectUnit,
    },
    data() {
      var validateFirstParty = (rule, value, callback) => {
        inpFirstPartyName(rule, value, callback)
      }
      return {
        // 表格参数
        searchForm: {
          current: 1,
          size: 10,
        },
        unitList: [],
        unitInitList: [],
        tableData: [],
        // 分页参数
        maintenRuleTotal: 0,
        // dialog参数
        tit: '',
        dialogVisible: false,
        loading: false,
        maintenRuleForm: {
          unitName: null,
          veriface: 0,
        },
        rulesMode: [], // 验证方式
        rulesRemind: [], // 维保提醒
        remindTime: [], // 维保提醒时间
        value: '',
        formRules: {
          unitName: [{
            required: true,
            message: '请选择生效单位',
            trigger: 'blur'
          }],
          radius: [{
            required: false,
            validator: validateTime,
            trigger: 'blur'
          }]
        },
        selectOtherDialogVisible: false, // 选择单位
        maintenRuleFormDisabled: false, // 查看禁点
        selectData: [],

        itemKey: 0,
        dialogType: '',
        maintainPersonn: false,
        tableLoading: true,
        editShow:true
      }
    },
    filters: {},
    methods: {
      // 获取字典数据
      getDictionar(code) {
        // code = code.join()
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {

            if (res.data.code === 200) {
              const {
                data: {
                  data
                },
              } = res
              this.unitList = data.MAINTEN_REMINDER_TIME
              this.unitList.forEach((item) => {
                item.time = '07:00'
                item.switch = 0
              })

              this.unitInitList = JSON.parse(JSON.stringify(this.unitList))
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      // 分页
      maintenRulePage(data) {
        this.searchForm.current = data.page
        this.searchForm.size = data.limit
        this.onRefer()
      },

      changePerson(item) {
        this.maintainPersonn = this.maintenRuleForm.maintainPersonnelType == 1
        if (this.maintenRuleForm.maintainPersonnelType == 1) {
          this.$set(this.maintenRuleForm, 'submitType', 0)
          this.$set(this.maintenRuleForm, 'maintainDurationType', 0)
        }
      },


      changeSwitch(data, index) {
        const tableDataArr = JSON.parse(JSON.stringify(this.unitList))
        this.$set(this, 'unitList', tableDataArr)
      },

      // 选择单位
      selectUnit() {
        console.log('选择单位')
        this.selectOtherDialogVisible = true
        console.log(this.maintenRuleForm, 'this.maintenRuleForm.eleIds');
        this.$nextTick(() => {
          this.$refs.SelectUnit.init(
            this.maintenRuleForm.orgIds,
            this.selectData,
            (refresh) => {
              if (refresh) {}
            }
          )
        })
      },
      // 选择单位返回
      selectOtherResult(data) {
        console.log('选择单位返回', data)
        const nameArr = []
        const idArr = []
        data.map((item) => {
          nameArr.push(item.name)
          idArr.push(item.id)
        })
        this.selectData = data
        this.selectOtherDialogVisible = false
        this.$set(this.maintenRuleForm, 'unitName', nameArr.join())
        // this.maintenRuleForm.unitName = nameArr.join()
        this.maintenRuleForm.orgIds = idArr
      },
      // 弹出框提交
      dioSub(type) {
        // this.loading = true
        let url = ''
        if (type === 'add') {
          url = '/api/ele/web/maintainRules/add'
        } else {
          url = '/api/ele/web/maintainRules/edit'
        }
        let remind = []
        this.unitList.map((item) => {
          this.remindTime.map((item1) => {
            if (item.value === item1) {
              remind.push({
                value: item.value,
                time: item.time,
                switch: item.switch
              })
            }
          })
        })

        this.$refs.maintenRuleForm.validate(async (valid) => {
          if (valid) {
            console.log(this.maintenRuleForm, 'this.maintenRule')
            const data = JSON.parse(JSON.stringify(this.maintenRuleForm))
            data.verificationMode = JSON.stringify(this.maintenRuleForm.verificationMode)
            data.maintainRemind = JSON.stringify(this.maintenRuleForm.maintainRemind)
            data.remindRules = JSON.stringify(this.unitList)
            data.radius = this.maintenRuleForm.radius == '' || this.maintenRuleForm.radius == 0 ? '1000' : this
              .maintenRuleForm.radius

            console.log(this.maintenRuleForm, 'this.maintenRule')
            console.log(data, 'data')
            this.$http.post(url, data).then((res) => {
              console.log(res, '弹出框提交')
              if (res.data.success) {
                this.searchForm = {
                  current: 1,
                  size: 10,
                }
                this.onRefer()
                this.dialogVisible = false
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }
        })
        this.loading = false
      },
      // 搜索
      searchOnRefer() {
        const regExp = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g
        if (regExp.test(this.searchForm.code)) {
          this.$message.error('标题仅可输入字母，数值，特殊符号!')
          return
        }
        this.searchForm.current = 1
        this.onRefer()
      },
      // 重置
      reset() {

        this.searchForm = {
          current: 1,
          size: 10,
        }
        this.onRefer()
      },
      // 获取维保规则方法
      onRefer() {
        this.tableLoading = true
        this.$http
          .post('/api/ele/web/maintainRules/getList', this.searchForm)
          .then((res) => {

            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.tableData = data.records ?? []
              this.maintenRuleTotal = data.total ?? 0
              this.$nextTick(() => {
                if (this.maintenRuleTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
            this.tableLoading = false
          })
      },
      // 查看维保规则
      edit(item, type) {
        this.$http.get(`/api/ele/web/maintainRules/checkRevise?id=${item.id}`).then((res)=>{
          if(res.data.code){
            this.editShow = res.data.data
          }
        })
        this.dialogType = type
        console.log(item, '编辑维保规则')
        this.tit = type == 'edit' ? '编辑维保规则' : '维保规则详情'
        this.dialogVisible = true
        this.maintenRuleFormDisabled = true
        const arr = JSON.parse(item.remindRules)
        if (arr && arr.length == 4) {
          let remindRules = JSON.parse(item.remindRules)
          this.unitList.map(item => {
            let sameItem = remindRules.find(rulesItem => rulesItem.value == item.value)
            if (sameItem) {
              item.time = sameItem.time
              item.switch = sameItem.switch
            }
          })
          this.$forceUpdate()
        } else {
          console.log(arr,'arr');
          arr.map((item) => {
            this.unitList.find(item1 => item1.value == item.value).switch = 1
          })
        }
        this.maintenRuleForm = {
          unitName: item.orgName,
          orgIds: item.orgIds,
          id: item.id,
          signIn: item.signIn,
          signOut: item.signOut,
          signature: item.signature,
          veriface: item.veriface,
          overdueOperation: item.overdueOperation,
          radius: item.radius,
          maintainPersonnelType: item.maintainPersonnelType,
          submitType: item.submitType,
          maintainDurationType: item.maintainDurationType,
          dayAdvance: item.dayAdvance,
          isSweep:item.isSweep,
          isMoveForward:item.isMoveForward,
          maintainRemind: JSON.parse(item.maintainRemind), // 维保提醒
          remindRules: JSON.parse(item.remindRules), // 维保提醒时间
          verificationMode: JSON.parse(item.verificationMode) //  验证方式
        }
      },
      // 删除规则
      del(item) {
        this.$confirm('此操作将永久删除选中的规则, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          const data = {
            id: item.id,
          }
          this.$http
            .post('/api/ele/web/maintainRules/delete', data)
            .then((res) => {

              if (res.data.success) {
                if (this.tableData.length === 1) {
                  this.searchForm.current -= 1
                }
                this.onRefer()
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
      },
      addDj() {
        this.dialogType = 'add'
        this.editShow = true
        this.tit = '添加维保规则'
        this.dialogVisible = true
        this.maintenRuleFormDisabled = false
        this.maintenRuleForm = {
          signIn: 1,
          signOut: 1,
          signature: 1,
          veriface: 0,
          maintainPersonnelType: 2,
          submitType: 1,
          maintainDurationType: 1,
          dayAdvance: 0,
          isMoveForward:0,
          isSweep:1,
          radius: '', // 签到半径
          overdueOperation: 100, // 超期后可维保
          maintainRemind: [1], // 维保提醒
          remindRules: [], // 维保提醒时间
          verificationMode: [3] //  验证方式

        }
      },
    },
    created() {
      this.getDictionar(['MAINTEN_REMINDER_TIME'])
    },
    mounted() {
      this.$nextTick(() => {
        this.onRefer()
      })
    },
  }
</script>
<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchBtn {
    margin-left: 15px;
  }

  .checkbox {
    width: 65%;

    /deep/.el-checkbox__label {
      width: 100%;
    }

    .fg {
      width: 15%;
      display: inline-block;
      text-align: center;
      margin-right: 5%;
    }
  }
</style>
