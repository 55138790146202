<template>
  <div style="display: flex;">
    <!-- 数据列表 -->
    <div style="width: 900px;">
      <el-form :inline="true" :model="searchList" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="searchList.eleName" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">计划时间</span>
          </span>
          <el-date-picker v-model="searchList.searchStartTime" type="daterange" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="ListOnfer">查询</el-button>
          <el-button type="warning" @click="Listreset">重置</el-button>
        </el-form-item>
      </el-form>
      <template v-if="Listtotal != 0">
        <el-table ref="multipleTable" :height="$store.state.tabHeight" :data="ListDate" highlight-current-row
          @current-change="checkRow" v-loading="ListLoading">
          <el-table-column type="index" label="编号" width="50px" />
          <el-table-column label="电梯名称" min-width="300px" align="center" prop="eleName" />
          <el-table-column label="计划时间" min-width="150px" align="center" prop="startTime" />
          <el-table-column label="签到时间" min-width="150px" align="center" prop="signTime" />
          <el-table-column label="完成时间" min-width="150px" align="center" prop="finishTime" />
          <el-table-column label="所属小区" min-width="200px" align="center" prop="plotName" />
          <el-table-column label="计划类型" min-width="150px" align="center" prop="maintainTypeName" />
          <el-table-column label="电梯注册代码" min-width="150px" align="center" prop="eleCode" />
          <el-table-column label="电梯安装位置" min-width="250px" align="center" prop="eleAddress" />
          <el-table-column label="物业单位" min-width="150px" align="center" prop="propertyUnitName" />
          <el-table-column label="维保单位" min-width="150px" align="center" prop="maintainUnitName" />
        </el-table>
        <Pagination :total="Listtotal" :layout="'total, sizes, prev, pager, next'" :page.sync="searchList.current"
          :limit.sync="searchList.size" @pagination="ListPage" />
      </template>
      <div class="nullDate" style="width: 900px;" v-else v-loading="ListLoading">
        <img v-if="!ListLoading" src="@/assets/nullDate.png" style="width: 240px" />
      </div>
    </div>
    <!-- 图片列表 -->
    <div class="imageList">
      <!-- <h4 style="margin: 0;color: #04d7c7;">图片列表</h4> -->
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
      <el-form-item>
        <span slot="label">
          <span class="lab-span">图片名称</span>
        </span>
        <el-input v-model="searchForm.name" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="searchBtn" @click="searchOnRefer">查询</el-button>
        <el-button type="danger" @click="delAll()">批量删除</el-button>
      </el-form-item>
      </el-form>
      <div>
        <template v-if="total != 0">
          <div style="display: flex;flex-wrap: wrap;height: 67vh;overflow-y: auto;" class="scrollElement" v-loading="Loading">
            <div v-for="(item, index) in tableData"
              style="margin-left: 20px;position: relative;background:#FFF;border-radius: 5px;margin-top: 20px;height: 32vh;"
              >
              <div v-show="item.looks" @mousemove="item.looks=true" @mouseout="checks(item,index)"
                style="width: 320px; position: absolute;z-index: 99;background: rgba(215, 215, 215, 1);height: 30px;display: flex;align-items: center;padding: 0px 5px;justify-content: space-between;">
                <div style="height: 15px;width: 15px;background: #FFF;cursor: pointer;" v-if='item.isShow'
                  @click="checkFn(index,item,1)">
                </div>
                <div
                  style="height: 15px;width: 15px;background: #409EFF;cursor: pointer;display: flex;justify-content: center;border-radius: 2px;align-items: end;"
                  v-else @click="checkFn(index,item,2)">
                  <i class="el-icon-check" style="font-size: 10px;"></i>
                </div>
                <i class="el-icon-circle-close" style="font-size: 25px;cursor: pointer;" @click="delOne(item)"></i>
              </div>
              <div @click="openDetail(item)" @mousemove="item.looks=true" @mouseout="checks(item,index)">
                <div class="imgBox" style="color: #000;line-height: 10px;">
                  <el-image style="width: 330px; height: 180px" :src="item.url" fit="cover" />
                  <div style="margin: 0px 10px;">
                    <p class="pInfo" :title="item.name">{{ item.name }}</p>
                    <h5 class="tpglH5" style="color: darkgrey;">
                      {{ item.userName }} ({{
                       item.capCode.substring(
                         item.capCode.length - 4,
                         item.capCode.length
                       )
                     }})
                    </h5>
                    <h5 class="tpglH5" style="color: darkgrey;">{{item.shootingTime}}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination :total="total" :layout="'total, sizes, prev, pager, next'" :page.sync="searchForm.current"
            :limit.sync="searchForm.size" @pagination="videoRulePage" />
        </template>
        <div class="nullDate" v-else>
          <img src="@/assets/nullDate.png" style="width: 260px" />
        </div>
        <DetailInfo v-if="detailInfoVisible" ref="detailInfo" :info="info" @detailClose="detailClose" />
      </div>
    </div>
    <!-- <div class="page-top">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">图片名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">拍摄时间</span>
          </span>
          <el-date-picker v-model="searchForm.allTime" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">人员名称</span>
          </span>
          <el-input v-model="searchForm.userName" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div> -->
    <!-- <div style="margin-bottom: 20px;">
      <el-button type="danger" size="small" @click="delAll()">批量删除</el-button>
    </div> -->
    <!-- <div>
      <template v-if="total != 0">
        <el-row>
          <template v-for="(item, index) in tableData">
            <el-col :span="6" :key="index" class="col20">
              <div v-show="item.looks" @mousemove="item.looks=true" @mouseout="checks(item,index)"
                style="width: 290px; position: absolute;z-index: 99;background: rgba(215, 215, 215, 1);height: 30px;display: flex;align-items: center;padding: 0px 5px;justify-content: space-between;">
                <div style="height: 15px;width: 15px;background: #FFF;cursor: pointer;" v-if='item.isShow'
                  @click="checkFn(index,item,1)">
                </div>
                <div
                  style="height: 15px;width: 15px;background: #409EFF;cursor: pointer;display: flex;justify-content: center;border-radius: 2px;align-items: end;"
                  v-else @click="checkFn(index,item,2)">
                  <i class="el-icon-check" style="font-size: 10px;"></i>
                </div>
                <i class="el-icon-circle-close" style="font-size: 25px;cursor: pointer;" @click="delOne(item)"></i>
              </div>

              <div @click="openDetail(item)"  @mousemove="item.looks=true" @mouseout="checks(item,index)">
                <div class="imgBox">
                  <el-image style="width: 100%; height: 180px" :src="item.url" fit="cover" />
                  <p class="boxTime">拍摄时间：{{ item.shootingTime }}</p>
                </div>
                <h5 class="tpglH5">{{ item.name }}</h5>
                <h5 class="tpglH5">
                  {{ item.userName }} ({{
                    item.capCode.substring(
                      item.capCode.length - 4,
                      item.capCode.length
                    )
                  }})
                </h5>
              </div>
            </el-col>
          </template>
        </el-row>
        <Pagination :total="total" :layout="'total, sizes, prev, pager, next'" :page.sync="searchForm.current"
          :limit.sync="searchForm.size" @pagination="videoRulePage" />
      </template>

      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
    </div> -->
    <!-- <DetailInfo v-if="detailInfoVisible" ref="detailInfo" :info="info" @detailClose="detailClose" /> -->
  </div>
</template>
<script>
  import Pagination from "../../../components/Pagination";
  import DetailInfo from "./components/detailInfo";
  import {
    formatDate
  } from '../../../util/index.js'
  export default {
    components: {
      Pagination,
      DetailInfo,
    },
    data() {
      return {
        //列表
        searchList: {
          current: 1,
          size: 10,
        },
        Listtotal: 0,
        ListDate: [],
        ListLoading: false,
        // 表格参数
        searchForm: {
          current: 1,
          size: 10,
        },
        tableData: [],
        // 分页参数
        total: 0,
        detailInfoVisible: false,
        info: {},
        Loading: true,
        delarr: [], //批量删除数据
      };
    },
    filters: {},
    methods: {
      //点击数据列表单条数据
      checkRow(data) {

        console.log(data, '条信息')
        this.searchForm.taskId = data.id
        this.onRefer();
      },
      Listreset() {
        console.log("重置");
        this.searchList = {
          current: 1,
          size: 10,
        };

        this.ListOnfer(true)
        // this.onRefer();
      },
      ListOnfer(type) {
        // this.ListLoading = true
        const data = JSON.parse(JSON.stringify(this.searchList))
        data.searchStartTime = this.searchList.searchStartTime ? [formatDate(this.searchList.searchStartTime[0],
          'yyyy-MM-dd 00:00:00'), formatDate(this.searchList.searchStartTime[1], 'yyyy-MM-dd 23:59:59')] : []
        this.$http.post('/api/ele/web/maintainTask/getTaskImageList', data).then((res) => {
          if (res.data.code == 200) {
            this.ListDate = res.data.data.records ?? []
            this.Listtotal = res.data.data.total
            if (type) {
              this.searchForm.taskId = res.data.data.records[0] ? res.data.data.records[0].id : ''
              if(!res.data.data.records[0]){
                this.tableData = []
              }
            }
            this.$nextTick(() => {
              if (res.data.data.records.length > 0 && this.$refs.multipleTable) {
                console.log(666)
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                this.$refs.multipleTable.setCurrentRow(this.ListDate[0])
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
          this.ListLoading = false
        })
      },
      //数据列表分页
      ListPage(data) {
        console.log(data,666)
        this.searchList.current = data.page;
        this.searchList.size = data.limit;
        this.ListOnfer(true);
      },
      // 分页
      // personnelPage(data) {
      //   this.searchForm.current = data.page;
      //   this.searchForm.size = data.limit;
      //   this.onRefer();
      // },
      checkFn(index, data, type) { //批量删除图片数据获取
        this.tableData[index].isShow = !this.tableData[index].isShow
        if (type == 1) {
          this.delarr.push(data)
          console.log(type, '数据1')
        } else {
          this.delarr = this.delarr.filter(item => item.id != data.id)
          console.log(type, '数据2')
        }
        console.log(this.delarr, 'arr')
      },
      delAll() { //
        let data = []
        if (this.delarr.length < 1) {
          this.$message({
            type: 'warning',
            message: '请选择要删除的图片'
          })
        } else {
          this.$confirm('此操作将永久删除记录，是否继续', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.delarr.forEach((item) => {
              data.push(item.id)
            })
            console.log(data, '批量删除')
            this.$http.post(`api/cap/web/images/removeIds`, data).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
                this.onRefer();
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
          })
        }
      },
      delOne(data) { //单个删除
        this.$confirm('此操作将永久删除记录，是否继续', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(data, '单个删除')
          this.$http.delete(`/api/cap/web/sos/delCapImages?id=${data.id}`).then((res) => {
            console.log(res, 'res')
            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.onRefer();
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          })
        })
      },
      checks(data, index) {
        if (this.delarr.find((item) => item.id == data.id)) {
          this.tableData[index].looks = true
        } else {
          this.tableData[index].looks = false
        }
      },

      // 搜索
      searchOnRefer() {
        console.log(this.searchForm, "this.searchForm");
        this.searchForm.current = 1;
        this.onRefer();
      },
      // 重置
      reset() {
        console.log("重置");
        this.searchForm = {
          current: 1,
          size: 10,
        };
        this.onRefer();
      },
      // 视频
      videoRulePage(data) {
        this.searchForm.current = data.page;
        this.searchForm.size = data.limit;
        this.onRefer();
      },
      // 获取人员方法
      onRefer() {
        this.Loading = true
        const data = JSON.parse(JSON.stringify(this.searchForm));
        data.start = this.searchForm.allTime ?
          this.searchForm.allTime[0].getTime() :
          "";
        data.end = this.searchForm.allTime ?
          this.searchForm.allTime[1].getTime() + 24 * 60 * 60 * 1000 - 1000 :
          "";
        this.$http.post("/api/cap/web/images/getCapImages", data).then((res) => {
          if (res.data.success) {
            console.log(res.data, 666)
            this.delarr = []
            const {
              data: {
                data
              },
            } = res;
            data.records.forEach((item, index) => {
              data.records[index].isShow = true
              data.records[index].looks = false
            })

            this.tableData = data.records ?? [];
            this.total = data.total ?? 0;
          } else {
            this.$message.error(res.data.msg);
          }
          this.Loading = false
        });
      },
      // 报警详情
      detail(row) {
        this.detailVisible = true;
      },

      openDetail(row) {
        this.info = row;
        this.detailInfoVisible = true;
        this.$nextTick(() => {
          this.$refs.detailInfo.init("tpxq");
        });
      },

      // 详情弹框关闭
      detailClose() {
        this.detailInfoVisible = false;
      },
    },
    created() {},
    mounted() {
      this.$nextTick(() => {
        // this.onRefer();
        this.ListOnfer(true)
      });
    },
  };
</script>
<style lang="scss" scoped>
  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h4 {
    margin: 0;
    font-size: 15px;
  }

  .imageList {
    padding: 20px 10px 20px 15px;
    width: 750px;
    border: 1px solid #fff;
    margin-left: 20px;
    height: 78vh;
    border-radius: 10px;
  }

  .mapBox {
    height: 50vh;
    background: #000;
    margin-left: 20px;
    border-radius: 5px;
  }

  .searchBtn {
    margin-left: 15px;
  }

  .pInfo {
    width: 300px;
    line-height: 20px;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    height: 20px;
    overflow: hidden;
  }


  .col20 {
    width: 20%;
    padding: 0 15px;
    color: #fff;
    margin-bottom: 10px;

    .imgBox {
      width: 430px;
      position: relative;
      overflow: hidden;

      .boxTime {
        position: absolute;
        bottom: 4px;
        margin: 0;
        background: #5c5c5cdb;
        padding: 8px;
        font-size: 14px;
        // width: 100%;
      }
    }

    h5 {
      margin: 10px 0;
      line-height: 17px;
    }
  }
</style>
