<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="formInline.eleName" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">注册代码</span>
          </span>
          <el-input v-model="formInline.eleCode" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">日期</span>
          </span>
          <template v-if="formInline.selectType==1">
            <el-date-picker style="width: 220px;" :key="1" v-model="formInline.allTime" type="daterange"
              :picker-options="pickerOptions" :default-time="['00:00:00','23:59:59']" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp">
            </el-date-picker>
          </template>
          <template v-else>
            <el-date-picker :key="2" :picker-options="pickerOptions" v-model="formInline.month" type="month"
              placeholder="选择月">
            </el-date-picker>
          </template>
        </el-form-item>
        <el-form-item>
          <el-radio-group v-model="formInline.selectType" @change="onRefer(true)">
            <el-radio-button :label="1">日</el-radio-button>
            <el-radio-button :label="2">月</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
          <el-button type="success" :disabled="exportType" @click="exportData()">{{exportTitle}}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="dictionarTotal!=0">
      <el-table :data="tableData" v-loading="loading" ref="multipleTable" style="width: 100%;margin-bottom: 20px;"
        :height="$store.state.tabHeight">
        <el-table-column fixed="left" type="index" label="序号" width="50px" />
        <el-table-column align='center' min-width="100" prop="eleName" label="电梯名称" />
        <el-table-column align='center' min-width="100" prop="eleCode" label="注册代码" />
        <el-table-column align='center' min-width="100" prop="eleType" label="电梯类型">
          <template slot-scope="scope">
            {{$getStatusName(scope.row.eleType,elevatorTypeList)}}
          </template>
        </el-table-column>
        <el-table-column align='center' min-width="200" prop="eleAddress" label="电梯安装位置" />
        <el-table-column align='center' min-width="100" prop="createTime" label="日期">
          <template slot-scope="scope">
            {{formInline.selectType==1?$formatDate(scope.row.createTime,'yyyy-MM-dd'):formInline.selectType==2?$formatDate(scope.row.createTime,'yyyy-MM'):''}}
          </template>
        </el-table-column>
        <el-table-column align='center' min-width="100" prop="monthNum" label="半月维保" />
        <el-table-column align='center' min-width="100" prop="quarterNum" label="季度维保" />
        <el-table-column align='center' min-width="100" prop="semiAnnualNum" label="半年维保" />
        <el-table-column align='center' min-width="100" prop="yearNum" label="年度维保" />
        <el-table-column align='center' min-width="120" label="维保平均用时">
          <template slot-scope="scope">
            {{scope.row._maintainTime}}H
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="dictionarPage" />
    </div>
    <div class="nullDate" v-else v-loading="loading">
      <img src="@/assets/nullDate.png" v-if="!loading" style="width: 260px;" />
    </div>
  </div>

</template>

<script>
  import {
    getMonthSE,
    formatDate
  } from '@/util'
  import Pagination from '@/components/Pagination'

  export default {
    components: {
      Pagination,
    },
    data() {
      return {

        // 表格参数
        formInline: {},
        tableData: [],
        // 分页参数
        dictionarTotal: 0,
        elevatorTypeList: [],
        loading: true,
        exportType: false,
        exportTitle: '导出'
      }
    },
    computed: {

      pickerOptions() {
        return {
          disabledDate: (time) => {
            if (!this.formInline.selectType) return true
            //今天以后的都禁用，2023年以前的禁用, 往前推30天可选
            let current_time = formatDate(new Date(), 'yyyy-MM-dd') + ' 23:59:59';
            current_time = new Date(current_time).getTime();
            let start_time = current_time - 30 * 8.64e7 + 1000
            let year = formatDate(time, 'yyyy')
            return this.formInline.selectType == 1 ? time.getTime() > current_time || year < 2023 || time.getTime() <
              start_time : time.getTime() > current_time || year < 2023
          }
        }
      },
    },
    methods: {
      initFormInline() {
        const date = new Date();
        // 获取当月第一天日期
        let startDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1)

        this.formInline = {
          current: 1,
          size: 10,
          selectType: this.formInline.selectType ? this.formInline.selectType : 1,
          allTime: [startDay.setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 59)],
          month: new Date()
        }
      },
      // 重置按钮事件
      reset() {
        this.initFormInline()
        this.onRefer()
      },
      // 导出
      async exportData() {
        this.formInline.current = 1
        console.log('adsadasdasdasdasd');
        this.exportType = true
        this.exportTitle = '正在导出'
        if ((this.formInline.selectType == 1 && !this.formInline.allTime) || (this.formInline.selectType == 2 && !this
            .formInline.month)) {
          this.$message.error('请先选择日期')
          return
        }
        if (this.formInline.selectType == 1) {
          this.formInline.startTime = this.formInline.allTime[0]
          this.formInline.endTime = this.formInline.allTime[1]
        } else {
          const {
            start,
            end
          } = getMonthSE(this.formInline.month.getFullYear(), this.formInline.month.getMonth())
          this.formInline.startTime = start
          this.formInline.endTime = end
        }
        let nowDate = new Date().getTime()
        if (this.formInline.endTime > nowDate) {
          this.formInline.endTime = nowDate
        }
        this.$http.post("/api/ele/web/export/maintainLocation", this.formInline, {
          responseType: 'blob'
        }).then((res) => {
          if (res.data.size > 0) {
            let blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            });
            let url = URL.createObjectURL(blob);
            const link = document.createElement('a'); //创建a标签
            link.href = url;
            link.download = '维保数据统计.xls'; //重命名文件
            link.click();
            URL.revokeObjectURL(url);
            console.log("下载文件" + res);
          } else {
            this.$message.error('暂无数据')
          }
          this.exportType = false
          this.exportTitle = '导出'
        })
      },
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      // 表格方法
      onRefer(ishm) {
        this.loading = true
        if ((this.formInline.selectType == 1 && !this.formInline.allTime) || (this.formInline.selectType == 2 && !this
            .formInline.month)) {
          this.$message.error('请先选择日期')
          return
        }
        if (ishm) {
          this.formInline.current = 1
        }
        if (this.formInline.selectType == 1) {
          this.formInline.startTime = this.formInline.allTime[0]
          this.formInline.endTime = this.formInline.allTime[1]
        } else {
          const {
            start,
            end
          } = getMonthSE(this.formInline.month.getFullYear(), this.formInline.month.getMonth())
          this.formInline.startTime = start
          this.formInline.endTime = end
        }
        let nowDate = new Date().getTime()
        if (this.formInline.endTime > nowDate) {
          this.formInline.endTime = nowDate
        }
        console.log(formatDate(this.formInline.startTime), formatDate(this.formInline.endTime))
        this.$http.post("/api/ele/web/maintainLocation/list", this.formInline)
          .then((res) => {
            if (res.data.success) {
              this.tableData = res.data.data.records.map(item => {
                const total = item.monthNum + item.quarterNum + item.semiAnnualNum + item.yearNum
                item._maintainTime = total ? (parseInt(item.maintainTime) / total / 60 / 60).toFixed(2) : 0
                return item
              })
              this.dictionarTotal = res.data.data.total
              this.$nextTick(() => {
                if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                  this.$refs.multipleTable.doLayout()
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
            this.loading = false
          })
      },
      //获取字典
      getDictionary(codes = ['ELEVATOR_TYPE']) {
        this.$http.post(`/api/system/web/dict/dictionary?codes=${codes}`)
          .then((res) => {
            if (res.data.success) {
              this.elevatorTypeList = res.data.data.ELEVATOR_TYPE
              this.onRefer()
            }
          })
      },

    },
    created() {
      this.initFormInline()
      this.getDictionary()
    }
  }
</script>

<style scoped>
  .pBox {
    font-size: 18px;
    display: flex;
  }

  .pSpan {
    width: 100px;
  }

  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-top {
    width: 100%;
    margin-bottom: 20px;
  }
</style>
