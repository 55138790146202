<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input
            v-model="formInline.eleName"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">救援识别码</span>
          </span>
          <el-input
            v-model="formInline.rescueCode"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">巡检人</span>
          </span>
          <el-input
            v-model="formInline.pollingUserName"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">巡检单位</span>
          </span>
          <el-input
            v-model="formInline.orgName"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">状态</span>
          </span>
          <el-select
            v-model="formInline.pollingStatus"
            clearable
            placeholder="请选择"
          >
            <el-option label="正常" value="0"></el-option>
            <el-option label="异常" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div style="display: flex">
        <el-button v-focus type="success" @click="addDj" size="small"
          >新增</el-button
        >
        <el-button v-focus type="danger" @click="delAll" size="small"
          >批量删除</el-button
        >
      </div>
    </div>
    <div v-if="dictionarTotal != 0">
      <el-table
        :data="tableData"
        ref="multipleTable"
        v-loading="loading"
        style="width: 100%; margin-bottom: 20px"
        :height="$store.state.tabHeight-60"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55px"> </el-table-column>
        <el-table-column type="index" label="序号" width="50px" />
        <!-- <el-table-column
          align="center"
          prop="propertyUnitName"
          label="物业单位"
          width="150px"
        /> -->
        <el-table-column
          align="center"
          prop="eleName"
          label="电梯名称"
          width="150px"
        />
        <el-table-column
          align="center"
          prop="rescueCode"
          label="救援识别码"
          width="150px"
        />
        <el-table-column
          align="center"
          prop="orgName"
          label="巡检单位"
          width="150px"
        />
        <el-table-column align="center" prop="pollingUserName" label="巡检人" />
        <el-table-column
          align="center"
          prop="pollingTime"
          label="巡检日期"
          width="150px"
        >
          <template slot-scope="scope">
            <!-- {{formatDate(new Date(scope.row.pollingTime), "yyyy-MM-dd HH:mm")}} -->
            {{ scope.row.pollingTime }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="巡检结果">
          <template slot-scope="scope">
            {{ scope.row.pollingStatus == 0 ? "正常" : "异常" }}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          align="center"
          prop="address"
          label="操作"
          width="300px"
        >
          <template slot-scope="scope">
            <el-button
              v-focus
              @click="show(scope.row)"
              type="primary"
              size="small"
              >查看</el-button
            >
            <el-button
              v-focus
              @click="upd(scope.row)"
              type="primary"
              size="small"
              >编辑</el-button
            >
            <el-button
              v-focus
              @click="del(scope.row.id)"
              type="danger"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="dictionarTotal"
        :page.sync="formInline.current"
        :limit.sync="formInline.size"
        @pagination="dictionarPage"
      />
    </div>
    <div class="nullDate" v-else v-loading="loading">
      <img src="@/assets/nullDate.png" v-if="!loading" style="width: 260px" />
    </div>
    <el-dialog
      v-if="dialogVisible"
      :close-on-click-modal="false"
      :title="tit + '电梯巡检'"
      :visible.sync="dialogVisible"
      width="950px"
      top="20vh"
      @closed="bclose"
    >
      <div
        style="height: 50vh; width: 100%; overflow-y: scroll"
        class="scrollElement"
      >
        <el-form
          :rules="rules"
          ref="ruleForm"
          label-position="right"
          label-width="100px"
          :model="fign"
        >
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="巡检电梯:" prop="eleName">
                <el-input
                  :placeholder="fign.eleName || '暂无内容'"
                  v-model="fign.eleName"
                  disabled
                  class="einp"
                >
                  <el-button
                    :disabled="tit == '查看'"
                    slot="append"
                    type="primary"
                    @click="selectElevator()"
                    >选择电梯</el-button
                  >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="巡检时间:" prop="pollingTime">
                <el-date-picker
                  class="einp"
                  v-model="fign.pollingTime"
                  type="date"
                  format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="巡检单位:" prop="orgId">
                <el-select
                  v-model="fign.orgId"
                  :disabled="tit == '查看'"
                  filterable
                  clearable
                  placeholder="请选择"
                  class="einp"
                  @change="changeUnit"
                >
                  <el-option
                    v-for="item in unitList"
                    :key="item.id"
                    :label="item.name+'（'+item.unitCode+'）'"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="巡检人员:" prop="pollingUser">
                <el-select
                  v-model="fign.pollingUser"
                  :disabled="tit == '查看' || selectUser"
                  filterable
                  clearable
                  placeholder="请选择"
                  class="einp"
                  @change="changeUser"
                >
                  <el-option
                    v-for="item in unitUser"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="巡检结果:" prop="pollingStatus">
                <el-select
                  v-model="fign.pollingStatus"
                  placeholder="请选择"
                  class="einp"
                  @change="changeStatus"
                >
                  <el-option label="正常" :value="0"></el-option>
                  <el-option label="异常" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0" class="memoStyle">
            <template v-for="item in pollingDetail">
              <el-col :span="24" :key="item.name" class="radioClass">
                <span class="memoSpan">{{ item.name }}:</span>
                <el-radio-group
                  v-model="item.status"
                  :disabled="tit === '查看'"
                  class="memoRadio"
                >
                  <el-radio :label="1">正常</el-radio>
                  <el-radio :label="2">异常</el-radio>
                  <el-radio :label="3">忽略</el-radio>
                </el-radio-group>
                <el-input
                  :disabled="tit == '查看'"
                  v-model="item.detail"
                  placeholder="请输入"
                  class="memoInput"
                />
              </el-col>
            </template>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="tit != '查看'"
          size="small"
          type="primary"
          @click="dioSub"
          >确 定</el-button
        >
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 错误信息列表 -->
    <ErrorDio
      v-if="errorList != null"
      :errorLists="errorList"
      @dioClose="dioCloseB"
    />

    <SelectElevator
      v-if="selectOtherDialogVisible"
      ref="SelectElevator"
      @transferElevator="selectOtherResult"
    />
  </div>
</template>
<script>
import ErrorDio from "@/components/errorDio.vue";
import Pagination from "@/components/Pagination";
import SelectElevator from "./components/selectElevator";
export default {
  components: {
    ErrorDio,
    Pagination,
    SelectElevator,
  },
  data() {
    let _this = this;
    return {
      // 错误信息列表
      loading:true,
      errorList: null,
      // 选中删除对象
      delData: [],
      // 表格参数
      formInline: {
        current: 1,
        size: 10,
      },
      tableData: [],
      // 分页参数
      dictionarTotal: 0,
      // dialog参数
      tit: "",
      dialogVisible: false,
      fign: {},
      // 表单限制
      rules: {
        eleName: [
          { required: true, message: "巡检电梯不能为空", trigger: "blur" },
        ],
        pollingTime: [
          { required: true, message: "巡检时间不能为空", trigger: "blur" },
        ],
        orgId: [
          { required: true, message: "巡检单位不能为空", trigger: "change" },
        ],
        pollingUser: [
          { required: true, message: "巡检人员不能为空", trigger: "change" },
        ],
      },
      pollingDetail: [],
      pollingInit: [
        { name: "电梯机房配电箱", status: 1, detail: "" },
        { name: "机房卫生环境", status: 1, detail: "" },
        { name: "机房温度（5-40度）", status: 1, detail: "" },
        { name: "控制屏声音运行情况", status: 1, detail: "" },
        { name: "变频器风扇运行情况", status: 1, detail: "" },
        { name: "主机运行状态", status: 1, detail: "" },
        { name: "轿厢照明）", status: 1, detail: "" },
        { name: "轿厢（平层度）", status: 1, detail: "" },
        { name: "轿厢（应急照明）", status: 1, detail: "" },
        { name: "轿厢按钮，显示", status: 1, detail: "" },
        { name: "对讲机系统", status: 1, detail: "" },
        { name: "轿厢警铃", status: 1, detail: "" },
        { name: "轿厢运行情况", status: 1, detail: "" },
        { name: "层门碰撞情况", status: 1, detail: "" },
        { name: "层门渗水情况", status: 1, detail: "" },
        { name: "开关门是否正常", status: 1, detail: "" },
        { name: "底坑清洁，无积水", status: 1, detail: "" },
        { name: "消防开关外盖完善情况", status: 1, detail: "" },
      ],
      selectOtherDialogVisible: false,
      selectData: [],
      unitList: [],
      unitUser: [],
      selectUser: true
    };
  },
  methods: {
    getUnit() {
      const data = {
        current: 1,
        size: 9999,
        type: "TENEMENT_UNIT",
      };
      this.$http.post("/api/system/web/org/list", data).then((res) => {
        if (res.data.success) {
          this.unitList = res.data.data.records;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getUser() {
      const data = {
        current: 1,
        size: 9999,
        orgId: this.fign.orgId,
      };
      this.$http.post("/api/system/web/appUser/getPage", data).then((res) => {
        if (res.data.success) {
          this.unitUser = res.data.data.records
          this.$forceUpdate()
          // this.$set(this, 'unitUser', res.data.data.records)
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 单位下拉
    changeUnit(val) {
      this.selectUser = false
      this.fign.pollingUser = ''
      this.getUser()
    },
    // 人员下来
    changeUser(val) {
      if (val) {
        this.$set(this.fign, 'pollingUser', val)
      }
      this.$forceUpdate()
      console.log(this.fign);
    },

    // 错误列表回调
    dioCloseB() {
      console.log(123);
      this.errorList = null;
    },
    // 重置按钮事件
    reset() {
      console.log("重置");
      this.formInline = {
        current: 1,
        size: 10,
      };
      this.onRefer();
    },
    // 分页
    dictionarPage(data) {
      this.formInline.current = data.page;
      this.formInline.size = data.limit;
      this.onRefer();
    },

    // 选择电梯
    selectElevator() {
      this.selectOtherDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.SelectElevator.init(
          this.fign.eleId,
          this.selectData,
          (refresh) => {
            if (refresh) {
            }
          }
        );
      });
    },
    // 选择电梯返回
    selectOtherResult(data) {
      const nameArr = [];
      const idArr = [];
      data.map((item) => {
        nameArr.push(item.name);
        idArr.push(item.id);
      });
      this.selectData = data;
      this.selectOtherDialogVisible = false;
      this.$set(this.fign, "eleName", nameArr.join());
      this.fign.eleId = idArr.join();
    },

    changeStatus(){
      this.$forceUpdate()
    },
    // 弹出框提交
    dioSub() {
      console.log(this.fign,'this.fign');
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.fign));
          data.pollingTime = new Date(
            new Date(Date.parse(this.fign.pollingTime)).getTime() +
              8 * 60 * 60 * 1000
          );
          data.pollingDetail = JSON.stringify(this.pollingDetail);
          let url = "/api/ele/web/polling/submit";
          this.$http.post(url, data).then((res) => {
            if (res.data.success) {
              this.onRefer();
              this.dialogVisible = false;
              this.selectData = [];
              this.$message.success(res.data.msg);
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          this.$message.warning("请验证填写内容!");
          return false;
        }
      });
    },

    // 表格方法
    onRefer(ishm) {
      if (ishm) {
        this.formInline.current = 1;
      }
      this.$http
        .post("/api/ele/web/polling/list", this.formInline)
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data.records;
            this.dictionarTotal = res.data.data.total;
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg);
          }
          this.loading = false
        });
    },
    // 修改
    upd(item) {
      this.tit = "修改";
      this.fign = JSON.parse(JSON.stringify(item));
      this.pollingDetail = JSON.parse(this.fign.pollingDetail);
      this.dialogVisible = true;
      this.selectUser = false
      console.log(this.fign,'人员')
      if(item.orgId){
        this.getUser()
      }else{
        this.selectUser = true
      }
    },
    addDj() {
      this.tit = "新增";
      this.fign = {
        pollingUser: ''
      };
      this.selectData = [];
      this.pollingDetail = JSON.parse(JSON.stringify(this.pollingInit));
      this.fign.pollingStatus = 0;
      this.dialogVisible = true;
      this.selectUser = true
    },
    delAll() {
      if (this.delData.length > 0) {
        let arr = [];
        this.delData.forEach((qs) => {
          arr.push(qs.id);
        });
        this.del(arr.join(","));
      } else {
        this.$message.warning("请选择要删除的记录");
      }
    },
    del(val) {
      this.$confirm("此操作将永久删除选中的记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(val, "del");
          this.$http
            .delete(`/api/ele/web/polling/remove?ids=${val}`)
            .then((res) => {
              if (res.data.success) {
                this.onRefer();
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(val) {
      this.delData = val;
    },
    bclose() {
      this.dialogVisible = false;
      this.pollingDetail;
    },
    show(item) {
      this.tit = "查看";
      this.fign = JSON.parse(JSON.stringify(item));
      this.pollingDetail = JSON.parse(this.fign.pollingDetail);
      this.dialogVisible = true;
      this.getUser()
    },
  },
  created() {
    this.onRefer();
    this.getUnit();
  },
};
</script>
<style lang="scss" scoped>
.radioClass {
  margin-bottom: 10px;
  /deep/.el-radio {
    color: #fff;
  }
  /deep/.is-checked {
    span {
      color: #00f6ff !important;
    }
  }
}

.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-top {
  width: 100%;
  margin-bottom: 20px;
}

.page-bom {
}
</style>
