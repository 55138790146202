<template>
  <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="950px"
    top="20vh" @close="closeDialog">
    <div class="searchBox scrollElement" style="height: 50vh;overflow-y: scroll; padding-right: 20px ;">
      <el-form ref="dictionarForm" label-width="120px" label-position="right">
        <el-row>
          <el-col :span="12">
            <el-form-item label="电梯名称:">
              <el-input v-model="eleInfo.name" disabled placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电梯注册代码:">
              <el-input v-model="eleInfo.code" disabled placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="救援识别码:">
              <el-input v-model="eleInfo.rescueCode" disabled placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="物业单位:">
              <el-input v-model="eleInfo.propertyUnitName" disabled placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="维保单位:">
              <el-input v-model="eleInfo.maintainUnitName" disabled placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电梯类型:">
              <el-input v-model="eleInfo.elevatorType" disabled placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
        </el-row>




        <el-row>
          <el-col :span="24">
            <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-left: 30px">
              <el-tab-pane label="维保人员" name="maintain">

                <template v-for="(item, index) in mainItemsArr">
                  <el-form-item :label="`人员${index + 1}:`" :key="index">
                    <el-input disabled v-model="item.name" placeholder="请输入" style="width: 60%" />
                    <template v-if="item.name">
                      <!-- <el-button
                        type="primary"
                        style="padding: 10px 20px; margin-left: 15px"
                        @click="chooseItem(false)"
                      >
                        更换</el-button> -->
                      <el-button :disabled="mainItemsArr.length < 3 && !mainItemsArr[1].name" type="danger"
                        style="padding: 10px 20px; margin-left: 15px" @click="delItem(item, index)">
                        移除</el-button>
                    </template>
                    <template v-else>
                      <el-button type="primary" style="padding: 10px 20px; margin-left: 15px" @click="chooseItem">
                        选择人员</el-button>
                    </template>
                  </el-form-item>
                </template>
              </el-tab-pane>
              <el-tab-pane label="物业安全员" name="property">
                <template v-for="(item, index) in propertyItemsArr">
                  <el-form-item :label="`人员${index + 1}:`" :key="index">
                    <el-input disabled v-model="item.name" placeholder="请输入" style="width: 60%" />
                    <template v-if="item.name">
                      <!-- <el-button
                        type="primary"
                        style="padding: 10px 20px; margin-left: 15px"
                        @click="chooseItem(false)"
                      >
                        更换</el-button> -->
                      <el-button :disabled="propertyItemsArr.length < 3 && !propertyItemsArr[1].name" type="danger"
                        style="padding: 10px 20px; margin-left: 15px" @click="delItem(item, index)">
                        移除</el-button>
                    </template>
                    <template v-else>
                      <el-button type="primary" style="padding: 10px 20px; margin-left: 15px" @click="chooseItem">
                        选择人员</el-button>
                    </template>
                  </el-form-item>
                </template>
              </el-tab-pane>
              <el-tab-pane label="保险人员" name="insurance" v-if="!$store.state.isWBJB">
                <template v-for="(item, index) in insuranceItemsArr">
                  <el-form-item :label="`人员${index + 1}:`" :key="index">
                    <el-input disabled v-model="item.name" placeholder="请输入" style="width: 60%" />
                    <template v-if="item.name">
                      <!-- <el-button
                        type="primary"
                        style="padding: 10px 20px; margin-left: 15px"
                        @click="chooseItem(false)"
                      >
                        更换</el-button> -->
                      <el-button :disabled="insuranceItemsArr.length < 3 && !insuranceItemsArr[1].name" type="danger"
                        style="padding: 10px 20px; margin-left: 15px" @click="delItem(item, index)">
                        移除</el-button>
                    </template>
                    <template v-else>
                      <el-button type="primary" :disabled="eleInfo.insuranceUnitId == ''"
                        style="padding: 10px 20px; margin-left: 15px" @click="chooseItem">
                        选择人员</el-button>
                    </template>
                  </el-form-item>
                </template>
              </el-tab-pane>
              <el-tab-pane label="救援人员" name="emergency" v-if="!$store.state.isWBJB">
                <template v-for="(item, index) in emergencyItemsArr">
                  <el-form-item :label="`人员${index + 1}:`" :key="index">
                    <el-input disabled v-model="item.name" placeholder="请输入" style="width: 60%" />
                    <template v-if="item.name">
                      <!-- <el-button
                        type="primary"
                        style="padding: 10px 20px; margin-left: 15px"
                        @click="chooseItem(false)"
                      >
                        更换</el-button> -->
                      <el-button :disabled="emergencyItemsArr.length < 3 && !emergencyItemsArr[1].name" type="danger"
                        style="padding: 10px 20px; margin-left: 15px" @click="delItem(item, index)">
                        移除</el-button>
                    </template>
                    <template v-else>
                      <el-button type="primary" style="padding: 10px 20px; margin-left: 15px" @click="chooseItem">
                        选择人员</el-button>
                    </template>
                  </el-form-item>
                </template>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <SelectPerson v-if="selectPersonVisible" ref="selectElevator" :unitName="unitName" :initIds="initIds"
      :changeType="true" :unitType="unitType" :orgId="orgId" @transferElevator="selectOtherResult" />
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确定</el-button>
      <el-button type="indo" @click="closeDialog">取消</el-button>
    </div>
    <!-- </div> -->
  </el-dialog>
</template>

<script>
  import SelectPerson from './selectPerson'
  export default {
    components: {
      SelectPerson,
    },
    data() {
      return {
        loading: true,
        visible: false,
        title: '更换人员', // 弹窗标题
        orgId: '', // 单位id
        unitName: '', // 单位name
        initIds: [], // 选择人员id集合
        selectPersonVisible: false, // 选择人员弹框
        addBrandCallback: null, // 弹窗回调

        mainItemsArr: [], // 表单信息
        maintainArr: [],
        maintainUserIds: [],
        maintainInitArr: [],

        propertyItemsArr: [], // 表单信息
        propertyArr: [],
        propertyUserIds: [],
        propertyInitArr: [],

        insuranceItemsArr: [], // 表单信息
        insuranceArr: [],
        insuranceUserIds: [],
        insuranceInitArr: [],

        emergencyItemsArr: [], // 表单信息
        emergencyArr: [],
        emergencyUserIds: [],
        emergencyInitArr: [],

        activeName: 'maintain',
        unitType: 'MAINTAIN_UNIT',

      }
    },
    props: {
      eleInfo: {
        type: Object,
        default: () => ({}),
      },
    },

    created() {},

    methods: {
      // 新增/修改，父字典id，父字典信息，字典信息，回调
      init(callback) {
        this.visible = true
        this.loading = false
        this.addBrandCallback = callback
        this.maintainInitArr = this.eleInfo.maintainUserNames ? this.eleInfo.maintainUserNames.split(';') : []
        this.propertyInitArr = this.eleInfo.propertyUnitUserNames ? this.eleInfo.propertyUnitUserNames.split(';') : []
        this.insuranceInitArr = this.eleInfo.insuranceUnitUserNames ? this.eleInfo.insuranceUnitUserNames.split(';') :
        []
        this.emergencyInitArr = this.eleInfo.emergencyRescueUserNames ? this.eleInfo.emergencyRescueUserNames.split(
          ';') : []

        // 维保
        this.maintainUserIds = this.eleInfo.maintainUserIds
        this.maintainInitArr.map((item) => {
          this.mainItemsArr.push({
            name: item
          })
        })
        if (this.mainItemsArr.length < 9) {
          this.mainItemsArr.push({
            name: ''
          })
        }

        // 物业
        this.propertyUserIds = this.eleInfo.propertyUnitUserIds
        this.propertyInitArr.map((item) => {
          this.propertyItemsArr.push({
            name: item
          })
        })
        if (this.propertyItemsArr.length < 9) {
          this.propertyItemsArr.push({
            name: ''
          })
        }

        // 保险
        this.insuranceUserIds = this.eleInfo.insuranceUnitUserIds
        console.log(this.insuranceInitArr, 'this.insuranceInitArr');
        if (this.insuranceInitArr && this.insuranceInitArr.length > 0) {
          this.insuranceInitArr.map((item) => {
            this.insuranceItemsArr.push({
              name: item
            })
          })
          if (this.insuranceItemsArr.length < 9) {
            this.insuranceItemsArr.push({
              name: ''
            })
          }
        } else {
          this.insuranceItemsArr.push({
            name: ''
          })
        }

        // 救援
        this.emergencyUserIds = this.eleInfo.emergencyRescueUserIds
        this.emergencyInitArr.map((item) => {
          this.emergencyItemsArr.push({
            name: item
          })
        })
        console.log(this.emergencyItemsArr, 'this.emergencyItemsArr');
        if (this.emergencyItemsArr.length < 9) {
          this.emergencyItemsArr.push({
            name: ''
          })
        }
      },

      submit() {
        const data = {}
        data.id = this.eleInfo.id
        data.maintainUserIds = this.maintainUserIds
        data.propertyUnitUserIds = this.propertyUserIds
        data.insuranceUnitUserIds = this.insuranceUserIds
        data.emergencyRescueUserIds = this.emergencyUserIds

        if (this.maintainUserIds.length === 0 ) {
          this.$message.warning('维保人员至少保留一个！')
          return
        }
        if (this.propertyUserIds.length === 0 ) {
          this.$message.warning('安全员至少保留一个！')
          return
        }
        // if (this.insuranceUserIds.length === 0) {
        //   this.$message.warning('保险人员至少保留一个!')
        //   return
        // }

        if (this.emergencyUserIds.length === 0 && !this.$store.state.isWBJB) {
          this.$message.warning('救援人员至少保留一个!')
          return
        }

        this.$http
          .post('/api/ele/web/elevatorInfo/updateElevatorUser', data)
          .then((res) => {
            if (res.data.success) {
              this.$message({
                message: '添加成功！',
                type: 'sussess',
              })
              if (this.addBrandCallback) {
                this.addBrandCallback(true)
              }
              this.closeDialog()
            } else {
              this.$message.warning(res.data.msg)
            }
          })
      },
      // 选择人员
      chooseItem(type) {
        console.log(type, 'changePerson');
        // 已选人员集合
        let initArr = []
        switch (this.activeName) {
          case 'maintain':
            this.orgId = this.eleInfo.maintainUnitId
            this.unitName = this.eleInfo.maintainUnitName
            initArr = this.maintainArr
            this.initIds = this.maintainUserIds ?? []
            break;
          case 'property':
            this.orgId = this.eleInfo.propertyUnitId
            this.unitName = this.eleInfo.propertyUnitName
            initArr = this.propertyArr
            this.initIds = this.propertyUserIds ?? []
            break;
          case 'insurance':
            this.orgId = this.eleInfo.insuranceUnitId
            this.unitName = this.eleInfo.insuranceUnitName
            initArr = this.insuranceArr
            this.initIds = this.insuranceUserIds ?? []
            break;

          case 'emergency':
            this.orgId = this.eleInfo.emergencyRescueUnitId
            this.unitName = this.eleInfo.emergencyRescueUnitName
            initArr = this.emergencyArr
            this.initIds = this.emergencyUserIds ?? []
            break;
        }
        this.selectPersonVisible = true
        this.$nextTick(() => {
          this.$refs.selectElevator.init(initArr, (refresh) => {
            if (refresh) {}
          })
        })
      },

      // 选择人员返回
      selectOtherResult(data) {
        this.selectPersonVisible = false
        switch (this.activeName) {
          case 'maintain':
            this.maintainUserIds = []
            this.mainItemsArr = this.mainItemsArr.slice(0, -1)
            if (data.length === 0) {
              this.mainItemsArr = [{
                name: ''
              }]
              this.maintainArr = data
              data.map((item) => {
                this.maintainUserIds.push(item.id)
              })
            } else {
              this.mainItemsArr = data
              this.maintainArr = data

              data.map((item) => {
                this.maintainUserIds.push(item.id)
              })
              if (this.mainItemsArr.length < 9) {
                this.mainItemsArr.push({
                  name: ''
                })
              }
            }
            break;
          case 'property':
            this.propertyUserIds = []
            this.propertyItemsArr = this.propertyItemsArr.slice(0, -1)
            if (data.length === 0) {
              this.propertyItemsArr = [{
                name: ''
              }]
              this.propertyArr = data
              data.map((item) => {
                this.propertyUserIds.push(item.id)
              })
            } else {
              this.propertyItemsArr = data
              this.propertyArr = data
              data.map((item) => {
                this.propertyUserIds.push(item.id)
              })
              if (this.propertyItemsArr.length < 9) {
                this.propertyItemsArr.push({
                  name: ''
                })
              }
            }
            break;

          case 'insurance':
            this.insuranceUserIds = []
            this.insuranceItemsArr = this.insuranceItemsArr.slice(0, -1)
            if (data.length === 0) {
              this.insuranceItemsArr = [{
                name: ''
              }]
              this.insuranceArr = data
              data.map((item) => {
                this.insuranceUserIds.push(item.id)
              })
            } else {
              this.insuranceItemsArr = data
              this.insuranceArr = data
              data.map((item) => {
                this.insuranceUserIds.push(item.id)
              })
              if (this.insuranceItemsArr.length < 9) {
                this.insuranceItemsArr.push({
                  name: ''
                })
              }
            }
            break;

          case 'emergency':
            this.emergencyUserIds = []
            this.emergencyItemsArr = this.emergencyItemsArr.slice(0, -1)
            if (data.length === 0) {
              this.emergencyItemsArr = [{
                name: ''
              }]
              this.emergencyArr = data
              data.map((item) => {
                this.emergencyUserIds.push(item.id)
              })
            } else {
              this.emergencyItemsArr = data
              this.emergencyArr = data
              data.map((item) => {
                this.emergencyUserIds.push(item.id)
              })
              if (this.emergencyItemsArr.length < 9) {
                this.emergencyItemsArr.push({
                  name: ''
                })
              }
            }
            break;
        }
      },
      // 移除人员
      delItem(data, index) {
        switch (this.activeName) {
          case 'maintain':
            this.mainItemsArr.splice(index, 1)
            this.maintainUserIds.splice(index, 1)
            if (this.mainItemsArr.length < 9 && this.mainItemsArr[this.mainItemsArr.lenth - 1] && this.mainItemsArr[this
                .mainItemsArr.lenth - 1].name) {
              this.mainItemsArr.push({
                name: ''
              })
            }
            break;
          case 'property':
            this.propertyItemsArr.splice(index, 1)
            this.propertyUserIds.splice(index, 1)
            if (this.propertyItemsArr.length < 9 && this.propertyItemsArr[this.propertyItemsArr.lenth - 1] && this
              .propertyItemsArr[this.propertyItemsArr.lenth - 1].name) {
              this.propertyItemsArr.push({
                name: ''
              })
            }
            break;
          case 'insurance':
            this.insuranceItemsArr.splice(index, 1)
            this.insuranceUserIds.splice(index, 1)

            if (this.insuranceItemsArr.length < 9 && this.insuranceItemsArr[this.insuranceItemsArr.lenth - 1] && this
              .insuranceItemsArr[this.insuranceItemsArr.lenth - 1].name) {
              this.insuranceItemsArr.push({
                name: ''
              })
            }
            break;
          case 'emergency':
            this.emergencyItemsArr.splice(index, 1)
            this.emergencyUserIds.splice(index, 1)

            if (this.emergencyItemsArr.length < 9 && this.emergencyItemsArr[this.emergencyItemsArr.lenth - 1] && this
              .emergencyItemsArr[this.emergencyItemsArr.lenth - 1].name) {
              this.emergencyItemsArr.push({
                name: ''
              })
            }
            break;
        }
      },

      handleClick(tab) {
        this.activeName = tab.name
        switch (this.activeName) {
          case 'maintain':
            this.unitType = 'MAINTAIN_UNIT'
            break;

          case 'property':
            this.unitType = 'TENEMENT_UNIT'
            break;

          case 'emergency':
            this.unitType = 'RESCUE_UNIT'
            break;

          case 'insurance':
            this.unitType = 'INSURANCE_UNIT'
            break;
        }
      },


      closeDialog() {
        this.visible = false
        this.$emit('closePerson')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wid80 {
    width: 80%;
  }

  .wid96 {
    width: 96%;
  }

  .textarea {
    /deep/.el-textarea__inner {
      color: white !important;
      border: 1px solid #04e3ef !important;
      background-color: #1282a5 !important;
    }
  }
</style>
