<template>
  <el-dialog title="编辑" v-if="detailVisible" :visible.sync="detailVisible" :close-on-click-modal="false" @close="back"
    width="600px">
    <div style="height: 30vh;padding: 20px;overflow-y: scroll;" class="scrollElement">
      <el-form label-width="80px" ref="formList" :model="formList" :rules="rules">
        <el-form-item label="单位名称" prop="orgId">
          <el-input v-if="orderDate.length < 1" v-model="editData.orgName" disabled/>
          <el-select v-model="formList.orgId" style="width: 100%;" @click="changOrder" v-else>
            <el-option v-for="(item,index) in orderDate" :key="index" :value="item.id" :label="item.name+'（'+item.unitCode+'）'"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="formList.type" class="eleDetailSelect" style="width: 100%;" placeholder="请选择"
            @click="changType">
            <el-option key="1" :value="0" label="日管控"></el-option>
            <el-option key="2" :value="1" label="周排查"></el-option>
            <el-option key="3" :value="2" label="月调度"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="检查项" prop="checkItem">
          <el-input v-model="formList.checkItem" placeholder="请输入" @input="changItem" clearable
            maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="检查内容" prop="checkContent">
          <el-input v-model="formList.checkContent" type="textarea" :autosize="{ minRows: 4, maxRows: 4}" resize="none" placeholder="请输入" maxlength="200"
            @input="changContent" clearable></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确认</el-button>
      <el-button @click="back">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {
    update
  } from 'js-md5'
  export default {
    props: ['editData'],
    data() {
      return {
        detailVisible: true,
        orderDate: [],
        formList: {
          id: '',
          orgId: '',
          type: '',
          checkContent: '',
          checkItem: ''
        },
        rules: {
          orgId: [{
            required: true,
            message: '请输入',
            trigger: 'change'
          }],
          type: [{
            required: true,
            message: '请输入',
            trigger: 'change'
          }],
          checkContent: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }],
          checkItem: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }]
        }
      }
    },
    methods: {
      //获取单位信息
      getInfo() {
        this.$http.get(`/api/system/web/org/getUserOrgList`).then((res) => {
          console.log(res, 'res1')
          this.orderDate = res.data.data
          this.formList = {
            orgId: this.editData.orgId,
            type: this.editData.type,
            checkContent: this.editData.checkContent,
            checkItem: this.editData.checkItem
          }
          console.log(this.editData, 'editData')
          console.log(this.orderDate,'orderDate')
        })
      },
      changOrder(value) {
        this.formList.orgId = value
      },
      changType(value) {
        this.formList.type = value
      },
      changItem(value) {
        this.formList.checkItem = value
      },
      changContent(value) {
        this.formList.checkContent = value
      },
      back() {
        this.detailVisible = false
        this.$emit('close')
      },
      submit() {
        this.$refs.formList.validate((valid) => {
          if (valid) {
            this.formList.id = this.editData.id
            this.$http.post('/api/base/web/item/editRiskManageItem', this.formList).then((res) => {
              console.log(res, 'res')
              if (res.data.code == 200) {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
                this.detailVisible = false
                this.$emit('update')
              }else{
                 this.$message({
                type: 'error',
                message: res.data.msg
              })
              }
            })
          }
        })
      }
    },
    created() {
      this.getInfo()
    },
  }
</script>

<style>
</style>
