<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="checkPage">
      <el-tab-pane label="关联单位" name="first" v-if='unitShow'>
        <UnitRelated ref="UnitRelated" v-if="activeName == 'first'" />
      </el-tab-pane>
      <el-tab-pane label="关联人员" name="second" v-if="personShow">
        <PersonRelated ref="PersonRelated" v-if="activeName == 'second'" />
      </el-tab-pane>
      <el-tab-pane label="关联小区" name="third" v-if='plotShow'>
        <PlotRelated ref="PlotRelated" v-if="activeName == 'third'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import UnitRelated from './components/UnitRelated.vue'
  import PersonRelated from './components/PersonRelated.vue'
  import PlotRelated from './components/PlotRelated.vue'
  export default {
    components: {
      UnitRelated,
      PersonRelated,
      PlotRelated
    },
    data() {
      return {
        activeName: 'first',
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        unitShow: false,
        personShow: false,
        plotShow: false,
      }
    },
    // mounte(){

    // }
    mounted() {
      console.log(this.adminInfo.resource, '66')
      if (this.adminInfo.resource) {
        this.adminInfo.resource.forEach((item) => {
          if (item.name == "档案管理") {
            if (item.children) {
              item.children.forEach((ids) => {
                if (ids.name == "关联信息") {
                  if (ids.children) {
                    ids.children.forEach((idd) => {
                      // console.log(idd,'ddd')
                      this.activeName = ids.children.find(items => items.name == "关联单位") ? 'first' : (ids.children.find(items => items.name == "关联人员") ? 'second' : 'third')
                      if (idd.name == "关联小区") {
                        this.plotShow = true
                      } else if (idd.name == "关联人员") {
                        this.personShow = true
                      } else {
                        this.unitShow = true
                      }
                    })
                    // this.looks = true
                  } else {
                    // this.looks = false
                  }
                }
              })
            }
          }
        })
      }
    },
    methods: {
      checkPage(tab, event) {
        if (tab.name == 'first') {
          this.UnitRelated();
        } else if (tab.name == 'second') {
          this.PersonRelated();
        } else {
          this.PlotRelated();
        }
      },
      //人员信息
      PersonRelated() {
        this.$nextTick(() => {
          this.$refs.PersonRelated.init((refresh) => {
            if (refresh) {}
          })
        })
      },
      //小区信息
      PlotRelated() {
        this.$nextTick(() => {
          this.$refs.PlotRelated.init((refresh) => {
            if (refresh) {}
          })
        })
      },
      //单位信息
      UnitRelated() {
        this.$nextTick(() => {
          this.$refs.UnitRelated.init((refresh) => {
            if (refresh) {}
          })
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.el-tabs__item {
    font-size: 20px;
  }

  /deep/.el-form-style .el-form-item {
    margin-right: 20px;
  }

  /deep/.el-form-style .el-input {
    width: 250px;
  }
</style>
