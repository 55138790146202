<template>
  <div>
    <!-- 弹框 -->
    <MonitorInfo ref="MonitorInfo" v-if="MonitorInfoVisible" :eleInfo="eleInfo" :elevatorId="elevatorId"
      :deviceCode="deviceCode" @closeMonitor="closeMonitor" @close="MonitorInfoVisible = false" />

    <div class="distribute">
      <div class="distribute-num">
        <div v-loading="loading" class="board-card" @click="showMack(1)">
          <span>总电梯数(台)</span>
          <span>{{ eleDistri.eleCount }}</span>
        </div>
        <div v-loading="loading" class="board-card" @click="showMack(6)">
          <span>入网电梯数(台)</span>
          <span>{{ eleDistri.realCount }}</span>
        </div>
        <div v-loading="loading" class="board-card" @click="showMack(2)">
          <span>在线数(台)</span>
          <span>{{ eleDistri.onlineCount }}</span>
        </div>
        <div v-loading="loading" class="board-card" @click="showMack(3)">
          <span>故障数(台)</span>
          <span>{{ eleDistri.failureCount }}</span>
        </div>
        <div v-loading="loading" class="board-card" @click="showMack(4)">
          <span>即将年检数(台)</span>
          <span>{{ eleDistri.inspectionCount }}</span>
        </div>
        <div v-loading="loading" class="board-card" @click="showMack(5)">
          <span>合同即将到期数(台)</span>
          <span>{{ eleDistri.contractCount }}</span>
        </div>
      </div>
      <div class="distribute-img">
        <div style="width: auto%; height: 100%; display: flex">
          <div v-for="item in liftList" :style="{ color: item.color }" class="orientation-a">
            <i class="el-icon-location"></i>
            {{ item.lab }}
          </div>
        </div>
        <div style="display: flex;">
          <div v-if="lookShow" style="display: flex;align-items: center;padding: 0px 15px;" v-focus>
            <img src="@/assets/change.png" style="height: 25px;width: 25px;cursor: pointer;margin-right: 5px;"
              @click="changeMap()" />
            <el-button type="text" style="font-size: 16px;"
              @click="changeMap()">{{changeShow ? '地图查看' : '列表查看'}}</el-button>
          </div>
          <div class="surInp" v-if="showMapGD">
            <el-select ref="mylazy" v-select-loadmore="loadmore" :remote-method="loadmore" :loading="loading"
              v-model="seleLift" clearable filterable placeholder="请选择" @focus="mygetItem">
              <el-option v-for="item in liftArr" :key="item.id" :label="item.name" :value="item.deviceCode">
              </el-option>
            </el-select>
            <el-button :disabled="!showMapGD" @click="showSeleLift" type="primary" size="mini">定位</el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog v-if="detailVisible" :title="tit" :visible.sync="detailVisible" :close-on-click-modal="false"
      append-to-body width="1400px" top="8vh" @close="closeDetail">
      <div style="height: 60vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
        <div v-if="detailData.lenght != 0">
          <template v-if="type === 3">
            <el-table ref="multipleTable" v-loading="tableLoading" :data="detailData" highlight-current-row
              max-height="500px" style="width: 100%; margin-bottom: 20px">
              <el-table-column type="index" align="center" label="序号" width="50px" />
              <el-table-column align="center" prop="name" label="电梯名称" width="200px" />
              <el-table-column align="center" label="报警时间" width="200px">
                <template slot-scope="scope">
                  {{ scope.row.eleFailureTaskDTO.sosTime }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="接警时间" width="200px">
                <template slot-scope="scope">
                  {{ scope.row.eleFailureTaskDTO.callTime }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="到场时间" width="200px">
                <template slot-scope="scope">
                  {{ scope.row.eleFailureTaskDTO.presentTime }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="完成时间" width="200px">
                <template slot-scope="scope">
                  {{ scope.row.eleFailureTaskDTO.finishTime }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="报警类型" width="100px">
                <template slot-scope="scope">
                  {{
                    sosTypeDate.find(
                      (item) =>
                        item.value == scope.row.eleFailureTaskDTO.sosType
                    )
                      ? sosTypeDate.find(
                          (item) =>
                            item.value == scope.row.eleFailureTaskDTO.sosType
                        ).label
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="救援时长" width="100px">
                <template #header>
                  <div>
                    <span style="margin-right: 5px;">救援时长</span>
                    <el-tooltip class="item" effect="dark" content="救援时长 = 完成时间 -  报警时间" placement="top">
                      <i class="el-icon-warning-outline" style="color: #00f6ff;font-size: 16px;"></i>
                    </el-tooltip>
                  </div>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.eleFailureTaskDTO.useTime }}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="code" label="电梯注册代码" width="250px" />
              <el-table-column align="center" prop="mergerName" label="地域" width="150px" />
              <el-table-column align="center" prop="propertyUnitName" label="物业单位" width="180px" />
              <el-table-column align="center" prop="maintainUnitName" label="维保单位" width="180px" />
              <el-table-column align="center" prop="deviceCode" label="物联网设备号" width="200px" />
              <el-table-column align="center" prop="terminalId" label="电梯数字终端ID" width="180px" />
              <el-table-column align="center" prop="eleAddress" label="电梯安装位置" width="250px" />
            </el-table>
          </template>
          <el-table v-else ref="multipleTable" v-loading="tableLoading" :data="detailData" highlight-current-row
            max-height="500px" style="width: 100%; margin-bottom: 20px">
            <el-table-column type="index" align="center" label="序号" width="50px" />
            <el-table-column align="center" prop="name" label="电梯名称" width="200px" />
            <el-table-column align="center" prop="rescueCode" label="救援识别码" width="150px" />
            <el-table-column align="center" prop="code" label="电梯注册代码" width="250px" />
            <el-table-column v-if="type === 1 || type === 2" align="center" prop="createTime" label="添加时间"
              width="200px" />
            <el-table-column v-if="type === 4" align="center" prop="inspectionDate" label="年检时间" width="200px" />
            <el-table-column v-if="type === 5" align="center" prop="endTime" label="合同到期时间" width="200px" />
            <el-table-column align="center" prop="deviceCode" label="物联网设备号" width="200px" />
            <el-table-column align="center" prop="mergerName" label="所属区域" width="150px" />
            <el-table-column align="center" prop="sdAvailableSize" label="SD卡" />
            <el-table-column align="center" prop="terminalId" label="电梯数字终端ID" width="180px" />
            <el-table-column align="center" prop="propertyUnitName" label="物业单位" width="180px" />
            <el-table-column align="center" prop="maintainUnitName" label="维保单位" width="180px" />
            <el-table-column align="center" prop="maintainUserNames" label="维保人员" width="180px" />
            <el-table-column align="center" prop="eleAddress" label="电梯安装位置" width="250px" />
          </el-table>
          <Pagination :total="total" :page.sync="detailFrom.current" :limit.sync="detailFrom.size"
            @pagination="dictionarPage" />
        </div>
        <div class="nullDate" v-else v-loading="tableLoading">
          <img v-if="!tableLoading" src="@/assets/nullDate.png" style="width: 260px;" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>

    <div v-show='changeShow'>
      <div class="page-top" style="margin-top: 20px;">
        <el-form inline :model="searchForm">
          <el-form-item label="小区名称">
            <el-input placeholder="请输入小区名称" v-model="searchForm.name"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onRefer()">查询</el-button>
            <el-button type="warning" @click="reset()">重置</el-button>
            <el-button type="success" :disabled="exportType" @click="exportData()">{{exportTitle}}</el-button>
            <el-button type="success" @click="exportList()">导出记录</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="dictionarTotal != 0">
        <el-table ref="multipleTables" v-loading="tabloading" :data="tableData"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px" :height="$store.state.tabHeight-200">
          <el-table-column type="index" label="序号" />
          <el-table-column label="小区名称" prop="name" align="center" />
          <el-table-column label="所属区域" prop="mergerName" align="center" />
          <el-table-column label="总电梯数" prop="eleNum" align="center" />
          <el-table-column label="入网电梯数" prop="isRealEleNum" align="center" />
          <el-table-column label="在线数" prop="onlinesEleNum" align="center" />
          <el-table-column label="离线数" prop="unOnlineEleNum" align="center" />
          <el-table-column label="插屏数" prop="screenNum" align="center" />
          <el-table-column label="故障数" prop="failureEleNum" align="center" />
          <el-table-column label="未入网电梯数" prop="unIsRealEleNum" align="center" />
          <el-table-column label="即将年检数" prop="inspectionEleNum" align="center" />
          <el-table-column label="合同即将到期数" prop="contractEleNum" align="center" />
        </el-table>
      </div>
      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 240px" />
      </div>
      <Pagination :total="dictionarTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
        :pageSizes="[10, 20, 30, 50, 100]" @pagination="dictionarPages" />
    </div>
    <Runreport v-if="Listshow" @closeCheck="close" />
    <custom-map v-show="!changeShow" ref="customMap" class="allmap" @changeSHow="checkLook" @changeShowMapGD="
        (value) => {
          showMapGD = value;
        }
      "></custom-map>
  </div>
</template>

<script>
  let map;
  let isOne = true;
  let markers = [];
  let getDeTime;
  import CustomMap from "@/components/custom-map";
  import Runreport from './components/Runreport.vue';
  import Pagination from "@/components/Pagination";
  import MonitorInfo from "../dtjk/components/monitorInfo.vue";
  export default {
    components: {
      Pagination,
      MonitorInfo,
      CustomMap,
      Runreport
    },
    directives: {
      "select-loadmore": {
        bind(el, binding) {
          // 下拉框对象
          const SELECTWRAP_DOM = el.querySelector(
            ".el-select-dropdown .el-select-dropdown__wrap"
          );
          // 给下拉框增加滚动监听，
          SELECTWRAP_DOM.addEventListener("scroll", function() {
            // scrollHeight:当前所有选项的高度
            // scrollTop:滚动的距离
            // clientHeight:下拉框的高度
            const condition =
              this.scrollHeight - this.scrollTop <= this.clientHeight;
            // 当滚动条滚动到最底下的时候执行接口加载下一页
            if (condition) {
              binding.value();
            }
          });
        },
      },
    },
    data() {
      return {
        tabloading: false,
        Listshow: false,
        exportTitle: '导出',
        exportType: false,
        dictionarTotal: 0,
        tableData: [], //列表数据
        searchForm: {
          current: 1,
          size: 10,
        },
        changeShow: false,
        lookShow: false,
        showMapGD: false,
        // 数据框
        tit: "null",
        detailVisible: false,
        detailData: [],
        detailFrom: {
          current: 1,
          size: 10,
        },
        total: 0,
        // 弹框数据
        eleInfo: null, //总数据
        elevatorId: "", // 电梯id
        deviceCode: "", // 设备号
        MonitorInfoVisible: false,
        // 地图数据
        seleLift: "",
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        liftArr: [],
        eleDistri: {},
        liftList: [{
            lab: "正常电梯",
            color: "#72D9FF ",
          },
          {
            lab: "离线电梯",
            color: "#4c4c4c",
          },
          {
            lab: "故障电梯",
            color: "#ff6666",
          },
          {
            lab: "即将年检电梯",
            color: "#ffbe66",
          },
          {
            lab: "合同即将到期电梯",
            color: "#759119",
          },
          {
            lab: "未入网电梯",
            color: "#D7D7D7",
          },
        ],
        tableLoading: true,
        type: null, // 电梯类型
        sosTypeDate: null, // 报警类型
        loading: false, // 加载中状态开关
        itemCode: "", // Select绑定数据
        myitems: [{}], // Select选项
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        stopLoading: false, // 加载开关，加载所有后端数据后控制懒加载是否继续执行，通过后端返回数据来控制
        pageData: {
          // 懒加载相关参数，这里代表从第一条数据开始加载，一次加载20项
          pageNumber: 1,
          pageSize: 20,
        },
      };
    },
    methods: {
      //导出记录
      exportList() {
        this.Listshow = true
      },
      close() {
        this.Listshow = false
      },
      //导出
      async exportData(ishm) {
        this.searchForm.current = 1
        console.log('adsadasdasdasdasd');
        this.exportType = true
        this.$http.post("/api/ele/web/export/getPlotEleFromAreaDetails", this.searchForm).then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg);
            this.onRefer()
          } else {
            this.$message.success(res.data.msg);
          }
          this.exportType = false
        })
      },
      changeMap() { //选择地图或列表查看
        this.searchForm.current = 1
        this.changeShow = !this.changeShow
        this.onRefer()
      },
      onRefer() {
        this.tabloading = true
        const code = JSON.parse(sessionStorage.getItem('acode'))
        console.log(code, 66677)
        this.searchForm.areaCodes = JSON.stringify(code).length == 12 ? code : code +
          '000000'
        this.$http.post(`/api/ele/web/plot/getPlotEleFromArea`, this.searchForm).then((res) => {
          if (res.data.code) {
            this.tableData = res.data.data.records ?? []
            this.dictionarTotal = res.data.data.total
            this.tabloading = false
            // this.$nextTick(() => {
            //   if (this.dictionarTotal > 0 && this.$refs.multipleTables) {
            //     this.$refs.dictionarTotal.bodyWrapper.scrollTop = 0;
            //   }
            // })
          }
        })
        console.log(this.tabloading, 'this.tabloading')
      },
      dictionarPages(data) {
        this.searchForm.current = data.page;
        this.searchForm.size = data.limit;
        this.onRefer()
      },
      reset() {
        this.searchForm = {
          current: 1,
          size: 10
        }
        this.onRefer()
      },
      checkLook(type) {
        console.log(type, 665)
        if (type == 1) {
          this.lookShow = true
        } else {
          this.lookShow = false
        }

      },
      dictionarPage(data) {
        this.detailFrom.current = data.page;
        this.detailFrom.size = data.limit;
        this.showMack(this.type);
        return;
      },
      closeDetail() {
        this.tableLoading = true
        this.detailVisible = false;
      },
      showMack(type) {
        this.type = type;
        console.log(type, "showMack");
        const params = {
          status: type - 1,
        };
        // this.detailFrom.status = type
        let url =
          `/api/ele/web/elevatorInfo/getEleDistributionInfo?current=${this.detailFrom.current}&size=${this.detailFrom.size}`;
        if (type == 1 || type == 2) {
          this.tit = "电梯详情";
        } else if (type == 3) {
          this.tit = "故障电梯明细";
        } else if (type == 4) {
          this.tit = "即将年检电梯";
        } else if (type == 5) {
          this.tit = "合同即将到期数";
        } else if (type == 6) {
          this.tit = "入网电梯";
        }
        this.detailVisible = true;
        this.$http
          .get(url, {
            params,
          })
          .then((res) => {
            console.log("res", res);
            if (res.data.code === 200) {
              this.tableLoading = false
              this.detailData = res.data.data.records;
              this.total = res.data.data.total;
              this.$nextTick(() => {
                if (this.total > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      showSeleLift() {
        if (this.seleLift) {
          this.liftArr.forEach((qs) => {
            if (qs.deviceCode == this.seleLift) {
              if (qs.latitude && qs.longitude) {
                this.$refs.customMap.moveMapCenter([qs.longitude, qs.latitude]);
                // map.setZoomAndCenter(18, [qs.longitude, qs.latitude])
              } else {
                this.$message.warning("此设备未上报定位!");
              }
              return;
            }
          });
        } else {
          this.$message.warning("请选中一个可用设备后在进行定位操作!");
        }
      },
      // setMapMarker(data) {
      //   if (data == undefined) {
      //     return
      //   }

      //   map.remove(markers)
      //   map.clearMap()
      //   data.forEach((qs) => {
      //     if (qs.latitude && qs.longitude) {
      //       let isStatus = this.getStatus(qs.status)

      //       var icon = new AMap.Icon({
      //         size: new AMap.Size(40, 40), // 图标尺寸
      //         image: isStatus.icon, // Icon的图像
      //         imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
      //         imageSize: new AMap.Size(40, 40), // 根据所设置的大小拉伸或压缩图片
      //       })

      //       var marker = new AMap.Marker({
      //         position: [qs.longitude, qs.latitude], //位置
      //         icon: icon, //自定义中端帽子的图标
      //       })
      //       // 设置标题
      //       marker.setTitle('电梯名称:' + qs.name + ';设备号:' + qs.deviceCode)
      //       markers.push(marker)
      //       map.add(marker) //添加到地图

      //       let sosType = ''
      //       if (qs.status === 2) {
      //         sosType = this.sosTypeDate
      //           ? this.sosTypeDate.find((item) => item.value == qs.sosType).label
      //           : ''
      //       }

      //       let content =
      //         '<div style="background-color:' +
      //         isStatus.color +
      //         ';padding: 10px;color:white;">' +
      //         qs.name +
      //         '(' +
      //         isStatus.lab +
      //         ')' +
      //         '<br/>物联网设备编号:' +
      //         qs.deviceCode +
      //         '<br/>救援识别码:' +
      //         qs.rescueCode +
      //         '<br/>电梯注册代码:' +
      //         qs.code +
      //         '<br/>安装位置:' +
      //         qs.eleAddress

      //       if (qs.status < 2) {
      //         content = content + '</div>'
      //       } else if (qs.status === 2) {
      //         content =
      //           content +
      //           '<br/><div style="margin:5px 0; border-top: 1px solid #fff"></div>' +
      //           isStatus.lab +
      //           '时间:' +
      //           new Date(qs.inspectionDate).toLocaleString() +
      //           '<br/>报警类型:' +
      //           sosType +
      //           '</div>'
      //       } else {
      //         content =
      //           content +
      //           '<br/><div style="margin:5px 0; border-top: 1px solid #fff"></div>' +
      //           isStatus.lab +
      //           (qs.status !== 3 ? '时间:' : '发生时间:') +
      //           new Date(qs.inspectionDate).toLocaleString() +
      //           '</div>'
      //       }

      //       let infoWindow = new AMap.InfoWindow({
      //         //创建信息窗体
      //         content: content,
      //         offset: new AMap.Pixel(10, -40),
      //       })

      //       var onMarkerOver = function (e) {
      //         infoWindow.open(map, e.target.getPosition()) //打开信息窗体
      //       }
      //       var onMarkerOut = function () {
      //         infoWindow.close()
      //       }
      //       marker.on('mouseover', onMarkerOver) //绑定mouseover事件
      //       marker.on('mouseout', onMarkerOut) //绑定mouseout事件
      //       let that = this
      //       var onMarkerClick = function (e) {
      //         that.eleInfo = qs
      //         that.elevatorId = String(qs.id)
      //         that.deviceCode = String(qs.deviceCode)
      //         that.MonitorInfoVisible = true
      //       }
      //       marker.on('click', onMarkerClick) //绑定click事件
      //     }
      //   })
      //   if (isOne) {
      //     isOne = false
      //     map.setFitView()
      //   }
      // },
      closeMonitor() {
        this.MonitorInfoVisible = false;
      },
      getSosType() {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=SOS_TYPE`)
          .then((res) => {
            if (res.data.success) {
              const Arr = res.data.data.SOS_TYPE.filter((item) => {
                if (this.adminInfo.userName != 'admin') {
                  return item.value != '21' && item.value != '35' && item.value != '22'
                } else {
                  return item
                }
              })
              this.sosTypeDate = Arr;
            }
          });
      },
      /**
       *  API函数，从后端获取数据，给下拉选项重新赋值
       * val： 是字符串且不为空时是模糊搜索，是对象时是第一次获取焦点调用
       * lazy: 懒加载时传 true
       * return  无
       */
      mygetItem(val, lazy = false) {
        // this.loading = true  // 调用API前设置加载状态为true
        let param = {
          current: this.pageData.pageNumber,
          size: this.pageData.pageSize,
        }; //加载页和每页数据
        if (lazy == false) {
          // 如果不是懒加载，
          this.operationModeProps = []; // 把select选项数组重置为空
          param.current = 1; // 设置查询第一页，每页20条
          param.size = 20;
        }
        console.log(val, "val");
        console.log(param, "param");
        // 本函数在搜索时调用会自动传入搜索内容（val)，是字符串，此时使用远程搜索方式
        if (typeof val == "string") {
          this.$http
            .post("/api/ele/web/elevatorInfo/getEleDistributionList", param)
            .then((res) => {
              if (res.data.success) {
                this.liftArr.push(...res.data.data.records);
                if (this.liftArr.length >= res.data.data.total) {
                  this.stopLoading = true; // 设置停止懒加载为true
                  return; // 直接停止执行，否则会导致下面的网络请求数据处理添加导致重复数据
                }
              }
            });
        } else {
          // 如果是获取焦点调用本函数（第一次调用），参数val不是字符串，而是对象，此时直接查询第一页数据
          param.current = 1;
          param.size = 20;
          // 这里很重要，获取焦点第一次加载时打开懒加载开关，否则一个页面多个懒加载的的话会导致一个懒加载关闭其他都不能懒加载了。
          this.stopLoading = false;
          this.$http
            .post(`/api/ele/web/elevatorInfo/getEleDistributionList`, param)
            .then((res) => {
              if (res.data.success) {
                this.loading = false;
                this.liftArr = res.data.data.records;
              }
            });
        }
      },
      /**
       * 懒加载自定义指令调用的方法，此方法会在select选项加载到底部是自动触发
       * return  无
       */
      loadmore() {
        if (!this.stopLoading) {
          this.pageData.pageNumber++; // 搜索下一页
          // this.$refs.mylazy.query 获取当前搜索的字符
          this.mygetItem(this.$refs.mylazy.query, true); //调用后端接口获取下拉框数据，此时第二个参数必须传 true，懒加载方式调用后端接口函数
        }
      },
      getFacility() {
        this.$http
          .post(`/api/ele/web/elevatorInfo/getEleDistribution`, {})
          .then((res) => {
            if (res.data.success) {
              ;
              this.eleDistri = res.data.data.eleDistributionTop;
              // this.liftArr = res.data.data.eleList
              this.loading = false;
            }
          })
      },
      // getStatus(status) {
      //   let obj
      //   switch (status) {
      //     case 0:
      //       obj = {
      //         lab: '离线',
      //         color: '#4c4c4c',
      //         icon: require('@/assets/mapIcon/lx.svg'),
      //       }
      //       break
      //     case 1:
      //       obj = {
      //         lab: '在线',
      //         color: '#4c4c4c',
      //         icon: require('@/assets/mapIcon/zc.svg'),
      //       }
      //       break
      //     case 2:
      //       obj = {
      //         lab: '发生了报警',
      //         color: '#ffa0a0',
      //         icon: require('@/assets/mapIcon/bj.svg'),
      //       }
      //       break
      //     case 3:
      //       obj = {
      //         lab: '故障',
      //         color: '#ff6666',
      //         icon: require('@/assets/mapIcon/gz.svg'),
      //       }
      //       break
      //     case 4:
      //       obj = {
      //         lab: '即将年检',
      //         color: '#ffbe66',
      //         icon: require('@/assets/mapIcon/nj.svg'),
      //       }
      //       break
      //     case 5:
      //       obj = {
      //         lab: '即将合同到期',
      //         color: '#759119',
      //         icon: require('@/assets/mapIcon/dq.svg'),
      //       }
      //       break
      //   }
      //   return obj
      // },
    },
    created() {
      this.getSosType();
      this.getFacility();
      // setTimeout(() => {
      //   this.getFacility()
      //   getDeTime = setInterval(() => {
      //     this.getFacility()
      //   }, 59000)
      // }, 2000)
    },
    mounted() {
      this.loading = true;
      // map = new AMap.Map('allmap', {
      //   mapStyle: 'amap://styles/blue',
      // })
    },
    destroyed() {
      clearInterval(getDeTime);
      // this.$refs.customMap.removeMap()
    },
  };
</script>

<style>
  .amap-info-content {
    padding: 0px !important;
  }
</style>

<style scoped>
  .nullDate {
    width: 100%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input::-webkit-input-placeholder {
    color: #cccccc;
  }

  .qinp {
    width: 220px;
    height: 60%;
    background-color: #1282a5;
    margin-right: 10px;
    color: white;
  }

  .surInp {
    width: 300px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .orientation-a {
    cursor: pointer;
    width: auto;
    margin-right: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    font-size: 18px;
  }

  .distribute-num {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: space-between;
  }

  .distribute {
    width: 100%;
    height: 130px;
  }

  #allmap {
    width: 100%;
    height: 75vh;
  }
</style>
