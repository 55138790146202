<template>
  <div v-if="reFresh" id="chart" style="width: 100%;height: 45vh;" />
</template>

<script>
  import * as echarts from 'echarts';
  export default {
    // mixins: [resize],
    props: ['dateArray', 'echartDate'],
    data() {
      return {
        chart: null,
        reFresh: true
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.initChart()
      })
    },
    methods: {
      initChart() {
        this.chart = echarts.init(document.getElementById('chart'));
        this.setOptions()
      },
      setOptions() {
        console.log(this.dateArray, 'dataArray')
        // const labelOption = {
        //   rotate: app.config.rotate,
        //   align: app.config.align,
        //   verticalAlign: app.config.verticalAlign,
        //   position: app.config.position,
        //   distance: app.config.distance
        // };
        const options = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            // data: ["电池供电异常", "网络信号异常", "流量异常", "上线异常", "应用异常", "视频生成异常", "T卡异常", "固件升级异常", '视频通话异常', 'Flash异常','楼层学习异常'],
            // "HDMI异常",
            // "广告播放异常",
            show: true,
            top: 'bottom',
            padding: [50, 50, 0, 0],
            itemHeight: 10,
            itemWidth: 10,
            textStyle: {
              color: '#409EFF',
              fontSize: 12,
            },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '5%',
            containLabel: true
          },
          xAxis: [{
            type: 'category',
            axisTick: {
              show: false
            },
            // boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: '#4dc1ed',
                size: '14px'
              },
            },
            data: this.dateArray
          }],
          yAxis: [{
            type: 'value',
            axisLine: {
              lineStyle: {
                color: '#4dc1ed',
              },
            },
          }],
          series: [{
              name: '电池供电异常',
              type: 'bar',
              barGap: 0,
              itemStyle: {
                color: '#3aa1ff',
              },
              emphasis: {
                focus: 'series'
              },
              data: this.echartDate.battery
            },
            {
              name: '网络信号异常',
              type: 'bar',
              itemStyle: {
                color: '#5ddfcf',
              },
              emphasis: {
                focus: 'series'
              },
              data: this.echartDate.signal
            },
            {
              name: '流量异常',
              type: 'bar',
              itemStyle: {
                color: '#4ecb73',
              },
              emphasis: {
                focus: 'series'
              },
              data: this.echartDate.flow
            },
            {
              name: '上线异常',
              type: 'bar',
              itemStyle: {
                color: '#fbd437',
              },
              emphasis: {
                focus: 'series'
              },
              data: this.echartDate.topLine
            },
            {
              name: '应用异常',
              type: 'bar',
              itemStyle: {
                color: '#eaa674',
              },
              emphasis: {
                focus: 'series'
              },
              data: this.echartDate.apply
            },
            // {
            //   name: 'HDMI异常',
            //   type: 'bar',
            //   itemStyle: {
            //     color: '#435188',
            //   },
            //   emphasis: {
            //     focus: 'series'
            //   },
            //   data: [198, 77, 51, 69, 80, 400, 93]
            // },
            {
              name: '视频生成异常',
              type: 'bar',
              itemStyle: {
                color: '#8a7bd4',
              },
              emphasis: {
                focus: 'series'
              },
              data: this.echartDate.video
            },
            {
              name: 'T卡异常',
              type: 'bar',
              itemStyle: {
                color: '#975fe5',
              },
              emphasis: {
                focus: 'series'
              },
              data: this.echartDate.tcard
            },
            // {
            //   name: '广告播放异常',
            //   type: 'bar',
            //   itemStyle: {
            //     color: '#749fea',
            //   },
            //   emphasis: {
            //     focus: 'series'
            //   },
            //   data: [128, 66, 78, 101, 80, 86, 83]
            // },
            {
              name: '固件升级异常',
              type: 'bar',
              itemStyle: {
                color: '#3cb3a2',
              },
              emphasis: {
                focus: 'series'
              },
              data: this.echartDate.firmware
            },
            {
              name: '视频通话异常',
              type: 'bar',
              itemStyle: {
                color: '#8535f3',
              },
              emphasis: {
                focus: 'series'
              },
              data: this.echartDate.videoCall
            },
            {
              name: 'Flash异常',
              type: 'bar',
              itemStyle: {
                color: '#604ff1',
              },
              emphasis: {
                focus: 'series'
              },
              data: this.echartDate.flash
            },
            {
              name: '楼层学习异常 ',
              type: 'bar',
              itemStyle: {
                color: '#919ff1',
              },
              emphasis: {
                focus: 'series'
              },
              data: this.echartDate.init
            },
          ]
        };
        this.chart.setOption(options)
      }
    }
  }
</script>

<style>
</style>
