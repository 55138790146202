<template>
  <div>
    <!-- 用来显示地图 -->
    <!-- 可以不写宽度，但一定要有高度 -->
    <div id="container" ref="mapWB"></div>
    <el-button
      type="primary"
      size="mini"
      @click="start"
      style="margin: 15px 15px 15px 0"
      >轨迹回放</el-button
    >

    <el-button
      :disabled="disabled"
      type="primary"
      size="mini"
      @click="stop"
      style="margin: 15px"
      >暂停回放</el-button
    >
    <el-button
      :disabled="disabled"
      type="primary"
      size="mini"
      @click="again"
      style="margin: 15px"
      >继续回放</el-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      map: null,
      marker: null,
      temp: [],
      polyline: [],
      passedPolyline: [],
      startMarker: null,
      disabled: true,
      // 用于测试的轨迹数据，父组件传来的polylinefather也是这种格式
      lineArr: [],
    };
  },
  props: {
    polylinefather: {
      type: [String, Number, Array],
    },
  },
  //mounted()负责初始化的工作包括：1.生成地图2.确定初始中心点3.生成初始标记点
  mounted() {
    //生成一个地图对象，确定初始中心点
    var map = new AMap.Map("container", {
      // eslint-disable-line no-unused-vars
      zoom: 16, //级别
      // center: [116.478935, 39.997761], //中心点坐标
      center: [this.polylinefather[0][0], this.polylinefather[0][1]],
      viewMode: "2D", //使用2D视图
    });
    // 生成一个标记点marker：
    let lng = this.polylinefather[0][0];
    let lat = this.polylinefather[0][1];
    this.marker = new AMap.Marker({
      position: new AMap.LngLat(lng, lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
    });
    // 将创建的点标记添加到已有的地图实例：
    map.add(this.marker);
    this.map = map;
    this.polyline = new AMap.Polyline({
        map: this.map,
        path: this.polylinefather,
        // 添加dirImg可以自定义路线的箭头，支持Image和Canvas
        showDir: true,
        dirImg: "https://a.amap.com/jsapi_demos/static/images/mass0.png",
        strokeColor: "#28F", //线颜色
        strokeOpacity: 0.7, //线透明度
        strokeWeight: 10, //线宽
        strokeStyle: "solid", //线样式
    });
    // 创建了一个车辆的点用于运动
    this.startMarker = new AMap.Marker({
        map: this.map,
        // 第一个点为起点
        position: this.polylinefather[0],
        icon: require('@/assets/walk.gif'),
        offset: new AMap.Pixel(-35, -20),
    });
  },
  
  beforeDestroy(){
    // console.log(this.$refs.mapWB,'mapWB');
    // console.log(document.getElementById("mapWB"),'mapWB3333');
    this.removeMap()
  },
  //方法调用，实现轨迹的生成和删除
  methods: {
    start() {
      this.disabled = false;
      window.AMap.plugin("AMap.MoveAnimation", () => {})
      this.startMarker.moveAlong(this.polylinefather, {
        // 每一段的时长
        duration: 500, //可根据实际采集时间间隔设置
        autoRotation: true,
      });
    },

    stop() {
      this.startMarker.pauseMove();
    },
    again() {
      this.startMarker.resumeMove();
    },
    removeMap(){
      //解绑地图的点击事件
      // this.map.off("click", this.markerClick);
      //销毁地图，并清空地图容器
      this.map.destroy();
      //地图对象赋值为null
      this.map = null
      // console.log(document.getElementById("mapWB"),'mapWB11111111111');
      // //清除地图容器的 DOM 元素
      this.$refs.mapWB.remove(); //"container" 为指定 DOM 元素的id
    }
    
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 500px;
}
</style>
